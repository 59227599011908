import gambar0 from '../image/ASAM-JAWA-(PRODUK)/30gr.jpg';
import gambar1 from '../image/asamdaging75/DSCF2912.jpg';
import gambar2 from '../image/asamdaging100/DSCF2824.jpg';
import gambar3 from '../image/asamdaging150bb/DSCF2835.jpg';
import gambar4 from '../image/asamdaging150/DSCF2815.jpg';
import gambar5 from '../image/asamdaging250/DSCF2918.jpg';
import gambar52 from '../image/asamdaging250L/DSCF2898.jpg';
import gambar53 from '../image/asamdaging250bb/DSCF2903.jpg';
import gambar54 from '../image/asamdaging250bbb/DSCF2894.jpg';
import gambar6 from '../image/ASAM-JAWA-(PRODUK)/250 Gr DAGING KEMASAN EXPORT/DSCF0073.jpg';
import gambar7 from '../image/ASAM-JAWA-(PRODUK)/500 Gr DAGING/DSCF0059.jpg';
import gambar8 from '../image/ASAM-JAWA-(PRODUK)/1 Kg DAGING/DSCF0063.jpg';
import gambar9 from '../image/ASAM-JAWA-(PRODUK)/10 Kg DAGING 1 DOS ISI 2/DSCF0112.jpg';
import gambar10 from '../image/ASAM-JAWA-(PRODUK)/250 Gr BIJI KEMASAN BIG SUN/DSCF0051.jpg';
import gambar11 from '../image/ASAM-JAWA-(PRODUK)/400 Gr BIJI KEMASAN BARU/DSCF0081.jpg';
import gambar12 from '../image/ASAM-JAWA-(PRODUK)/500 Gr BIJI/DSCF0085.jpg';
import gambar13 from '../image/ASAM-JAWA-(PRODUK)/800 Gr BIJI BUNGA MERAH/DSCF0033.jpg';
import gambar14 from '../image/ASAM-JAWA-(PRODUK)/asam biji 1kg.jpg';
import gambar15 from '../image/asambm1kg/DSCF2854.jpg';
import gambar16 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/bahan-baku-1.jpeg';
import gambar17 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/bahan-baku-2.jpeg';
import gambar18 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/bahan-baku-3.jpeg';
import gambar19 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/bahan-baku-4.jpeg';
import gambar20 from '../image/ASAM-JAWA-(PRODUK)/40gr.jpeg';
import gambar21 from '../image/ASAM-JAWA-(PRODUK)/asam_jawa_daging_500.jpeg';
import gambar22 from '../image/ASAM-JAWA-(PRODUK)/35 gr Daging/DSCF1062.jpg';
import gambar23 from '../image/ASAM-JAWA-(PRODUK)/Adg 250gr BBB.jpg'
import gambar24 from '../image/ASAM-JAWA-(PRODUK)/asam_jawa_biji_10Kg.jpeg'
import gambar25 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/karung1.jpeg';
import gambar26 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/karung2.jpeg';
import gambar27 from '../image/ASAM-JAWA-(PRODUK)/Bahan-Baku/karung3.jpeg';
import gambar28 from '../image/ASAM-JAWA-(PRODUK)/bunga merah.png';
import gambar29 from '../image/kemiri.jpg';
import gambar30 from '../image/kayu-manis.webp';
import gambar31 from '../image/aneka-kerupuk.png';
import gambar32 from '../image/material1.jpeg';
import gambar33 from '../image/material2.jpeg';
import gambar34 from '../image/material3.jpeg';
import gambar35 from '../image/material4.jpeg';
import gambar36 from '../image/asambm800/DSCF2859.jpg'
import gambar37 from '../image/asambiji30/DSCF2845.jpg'

const  dataProduk = [
    // {
    //     id: {
    //         "nama": "Asam Biji 1 Kg Cap Bunga Merah",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 1 Kg Cap Bunga Merah adalah solusi sempurna bagi Anda yang menginginkan kualitas premium dalam bahan masakan. Dikemas dalam kemasan praktis berukuran 1 kilogram, produk ini menawarkan biji asam berkualitas tinggi yang dipilih secara selektif. Dengan citarasa asam yang segar dan khas, Asam Biji Cap Bunga Merah akan memberikan sentuhan istimewa pada hidangan Anda, meningkatkan tingkat kelezatan dan kesegaran masakan Anda.
    //         \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah yang menjadi jaminan mutu dan keaslian produk, menjadikan Asam Biji ini pilihan yang tepercaya untuk digunakan dalam berbagai resep masakan. Keunikan rasa asam yang dimilikinya cocok untuk menghadirkan variasi rasa yang menarik dalam hidangan tradisional maupun kreatif. Dengan Asam Biji 1 Kg Cap Bunga Merah, Anda dapat memasak dengan keyakinan akan kualitas dan autentisitas rasa yang selalu terjaga.
    //         `,
    //         "kategori": "biji",
    //         "image": gambar28,
    //         "ukuran": 1000,
    //         "jenis": "Jenis",
    //         "asam": "Asam Biji",
    //         "kemasan": "Kemasan",
    //         "tradisional": "Tradisional",
    //         "harga": "Harga",
    //         "total": "Rp 338.000/dus",
    //         "isi": "Isi",
    //         "total_isi": "20 Pack",
    // },
    //     en: {
    //         "nama": "Tamarind Seeds 1 Kg Red Flower Cap",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 Tamarind Seeds 1 Kg Red Flower Cap is the perfect solution for those of you who want premium quality in cooking ingredients. Packaged in practical 1 kilogram packaging, this product offers selectively selected high quality tamarind seeds. With its fresh and distinctive sour taste, Asam Biji Cap Bunga Merah will give a special touch to your dishes, increasing the level of deliciousness and freshness of your cooking.
    //         \u00a0\u00a0\u00a0\u00a0 The Red Flower stamp guarantees the quality and authenticity of the product, making Asam Biji a trusted choice for use in various recipes. Its unique sour taste is suitable for presenting interesting flavor variations in traditional and creative dishes. With Seed Asam 1 Kg Cap Bunga Merah, you can cook with confidence that the quality and authenticity of the taste will always be maintained.
    //         `,
    //         "kategori": "biji",
    //         "image": gambar28,
    //         "ukuran": 1000,
    //         "jenis": "Type",
    //         "asam": "Seed Acid",
    //         "kemasan": "Packaging",
    //         "tradisional": "Traditional",
    //         "harga": "Price",
    //         "total": "Rp 338.000/dus",
    //         "isi": "Fill",
    //         "total_isi": "20 Pack",
    //     },
    //     ar: {
    //         "nama": "بذور التمر الهندي 1 كجم غطاء زهرة حمراء",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 1 Kg Cap Bunga Merah هو الحل الأمثل لأولئك منكم الذين يريدون الجودة العالية في مكونات الطبخ. يقدم هذا المنتج، المعبأ في عبوة عملية بوزن 1 كيلوجرام، بذور تمر هندي عالية الجودة تم اختيارها بشكل انتقائي. بطعمه الحامض الطازج والمميز، سيضفي Asam Biji Cap Bunga Merah لمسة خاصة على أطباقك، مما يزيد من مستوى اللذة والنضارة في طبخك.
    //         \u00a0\u00a0\u00a0\u00a0 يضمن ختم الزهرة الحمراء جودة المنتج وأصالته، مما يجعل Asam Biji خيارًا موثوقًا به للاستخدام في الوصفات المختلفة. مذاقه الحامض الفريد مناسب لتقديم نكهات مختلفة مثيرة للاهتمام في الأطباق التقليدية والإبداعية. مع Seed Asam 1 Kg Cap Bunga Merah، يمكنك الطهي بثقة أنه سيتم الحفاظ دائمًا على جودة وأصالة المذاق.
    //         `,
    //         "kategori": "biji",
    //         "image": gambar28,
    //         "ukuran": 1000,
    //         "jenis": "يكتب",
    //         "asam": "حمض البذور",
    //         "kemasan": "التعبئة والتغليف",
    //         "tradisional": "تقليدي",
    //         "harga": "سعر",
    //         "total": "Rp 338.000/dus",
    //         "isi": "يملأ",
    //         "total_isi": "20 عبوة",
    //     },
    //     zh: {
    //         "nama": "罗望子种子 1 公斤 红花帽",
    //         "deskripsi": `
    //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 1 公斤 Cap Bunga Merah 是那些想要高品质烹饪原料的人的完美解决方案。 该产品采用实用的 1 公斤包装，提供精选的优质罗望子种子。 凭借其新鲜和独特的酸味，Asam Biji Cap Bunga Merah 将为您的菜肴带来特殊的风味，增加您烹饪的美味和新鲜度。
    //         \u00a0\u00a0\u00a0\u00a0 红花印记保证了产品的质量和真实性，使 Asam Biji 成为各种食谱中值得信赖的选择。 其独特的酸味适合在传统和创意菜肴中呈现有趣的风味变化。 使用 Seed Asam 1 Kg Cap Bunga Merah，您可以充满信心地烹饪，并始终保持味道的质量和真实性。
    //         `,
    //         "kategori": "biji",
    //         "image": gambar28,
    //         "ukuran": 1000,
    //         "jenis": "类型",
    //         "asam": "种子酸",
    //         "kemasan": "包装",
    //         "tradisional": "传统的",
    //         "harga": "价格",
    //         "total": "Rp 338.000/dus",
    //         "isi": "内容",
    //         "total_isi": "20 包",
    //     },
    // },
        // {
        //    id: {
        //         "nama": "Asam Daging 30 Gram Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Daging 30 Gram dari Brand Cap Gunung menghadirkan pengalaman kuliner yang unik dengan kelebihan kandungan biji sebesar 3 hingga 5%. Dikemas dengan teliti, produk ini menonjolkan keasaman yang terjaga dengan baik, menjadikannya pilihan ideal untuk memperkaya rasa hidangan Anda. Dengan berat 30 gram, Asam Daging ini tidak hanya memberikan kepraktisan dalam penggunaan sehari-hari, tetapi juga memberikan sentuhan khusus pada masakan Anda dengan tambahan biji yang memberikan dimensi rasa yang lebih kaya.
        //         \u00a0\u00a0\u00a0\u00a0 Brand Cap Gunung menghadirkan produk berkualitas tinggi yang diperkaya dengan keunikan, menjadikan Asam Daging 30 Gram ini sebagai pilihan istimewa. Kandungan biji yang terjaga dengan baik menambah kompleksitas rasa, memberikan nuansa asam yang segar dan tak terlupakan pada setiap hidangan. Jelajahi kreativitas kuliner Anda dengan Asam Daging 30 Gram dari Brand Cap Gunung, dan saksikan bagaimana tambahan biji ini memberikan sentuhan istimewa pada setiap suapan, menciptakan pengalaman kuliner yang tak terlupakan.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar0,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "Jenis",
        //         "asam": "Asam Biji",
        //         "kemasan": "Kemasan",
        //         "tradisional": "Tradisional",
        //         "harga": "Harga",
        //         "total": "Rp 405.500/dus",
        //         "isi": "Isi",
        //         "total_isi": "40 Pack",
        //     },
        //     en: {
        //         "nama": "Meat Sour 30 Grams Brand Mountain ",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Meat Tamarind 30 Grams from the Cap Gunung Brand presents a unique culinary experience with an excess seed content of 3 to 5%. Carefully packaged, this product boasts well-preserved acidity, making it an ideal choice to enrich the taste of your dishes. Weighing 30 grams, this Meat Tamarind not only provides practicality in everyday use, but also gives a special touch to your cooking with the addition of seeds which provide a richer flavor dimension.
        //         \u00a0\u00a0\u00a0\u00a0 The Cap Gunung Brand presents high quality products that are enriched with unique qualities, making this 30 Gram Meat Tamarind a special choice. Well-preserved seeds add complexity to the flavor, providing a fresh and unforgettable sour nuance to every dish. Explore your culinary creativity with 30 Gram Meat Tamarind from the Cap Gunung Brand, and see how the addition of these seeds adds a special touch to every bite, creating an unforgettable culinary experience.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar0,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "Type",
        //         "asam": "Seed Acid",
        //         "kemasan": "Packaging",
        //         "tradisional": "Traditional",
        //         "harga": "Price",
        //         "total": "Rp 405.500/dus",
        //         "isi": "Fill",
        //         "total_isi": "40 Pack",
        //     },
        //     ar: {
        //         "nama": "لحم حامض 30 جرام ختم جونونج",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 يقدم لحم التمر الهندي 30 جرامًا من ماركة Cap Gunung تجربة طهي فريدة من نوعها مع محتوى بذور زائد بنسبة 3 إلى 5٪. يتميز هذا المنتج المعبأ بعناية بحموضة محفوظة جيدًا، مما يجعله خيارًا مثاليًا لإثراء مذاق أطباقك. يزن هذا اللحم التمر الهندي 30 جرامًا، ولا يوفر التطبيق العملي في الاستخدام اليومي فحسب، بل يمنح أيضًا لمسة خاصة لطهيك مع إضافة البذور التي توفر بُعدًا أكثر ثراءً للنكهة.
        //         \u00a0\u00a0\u00a0\u00a0 تقدم العلامة التجارية Cap Gunung منتجات عالية الجودة غنية بصفات فريدة، مما يجعل 30 جرامًا من لحم التمر الهندي خيارًا خاصًا. تضيف البذور المحفوظة جيدًا تعقيدًا إلى النكهة، مما يوفر نكهة حامضة طازجة لا تُنسى لكل طبق. اكتشف إبداعك في الطهي مع 30 جرامًا من التمر الهندي باللحم من العلامة التجارية Cap Gunung، وشاهد كيف تضيف إضافة هذه البذور لمسة خاصة إلى كل قضمة، مما يخلق تجربة طهي لا تُنسى.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar0,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "يكتب",
        //         "asam": "حمض البذور",
        //         "kemasan": "التعبئة والتغليف",
        //         "tradisional": "تقليدي",
        //         "harga": "سعر",
        //         "total": "Rp 405.500/dus",
        //         "isi": "يملأ",
        //         "total_isi": "40 عبوة",
        //     },
        //     zh: {
        //         "nama": "酸肉 30 克邮票 Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 肉罗望子 30 克提供独特的烹饪体验，种子含量超标 3 至 5%。 该产品经过精心包装，酸度保存完好，是丰富菜肴味道的理想选择。 这款肉罗望子重 30 克，不仅在日常使用中提供实用性，而且通过添加种子提供更丰富的风味维度，为您的烹饪带来特殊的感觉。
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌提供富含独特品质的高品质产品，使这款 30 克肉罗望子成为特殊选择。 保存完好的种子增加了味道的复杂性，为每道菜带来新鲜和令人难忘的酸味。 使用 Cap Gunung 品牌的 30 克肉罗望子探索您的烹饪创造力，看看添加这些种子如何为每一口食物增添特殊的触感，创造难忘的烹饪体验。
        //         `,
        //         "kategori": "daging",
        //         "image": gambar0,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "类型",
        //         "asam": "种子酸",
        //         "kemasan": "包装",
        //         "tradisional": "传统的",
        //         "harga": "价格",
        //         "total": "Rp 405.500/dus",
        //         "isi": "内容",
        //         "total_isi": "40 包",
        //     },
        // },
        {
            id: {
                "nama": "Asam Daging 35 Gram Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 35 Gram dari brand Cap Gunung membawa inovasi unik dalam dunia kuliner dengan tambahan kandungan biji sebesar 3 hingga 5%. Kemasan dengan berat 35 gram ini mempersembahkan kemudahan dalam penggunaan sehari-hari, sementara keberadaan biji memberikan dimensi rasa yang lebih mendalam pada hidangan Anda. Brand Cap Gunung kembali menunjukkan komitmen tinggi mereka terhadap kualitas dengan menyajikan Asam Daging ini, yang tidak hanya menonjolkan keasaman yang terjaga secara optimal tetapi juga memberikan pengalaman kuliner yang lebih berwarna.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Daging 35 Gram dari brand Cap Gunung, Anda dapat menciptakan hidangan yang tidak hanya lezat tetapi juga memikat selera dengan keunikan kandungan biji yang dijaga sebaik mungkin. Kandungan biji ini memberikan sentuhan istimewa pada setiap suapan, menciptakan sensasi rasa yang tak terlupakan. Jelajahi ragam rasa baru dan nikmati kreativitas dalam memasak dengan Asam Daging 35 Gram, sebuah inovasi dari Brand Cap Gunung untuk menyempurnakan pengalaman kuliner Anda.
                `,
                "kategori": "daging",
                "image": gambar22,
                "ukuran": 35,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 405.000/dus",
                "isi": "Isi",
                "total_isi": "30 Pack",
                "pack": "1 Pack",
                "total_pack": "12 renceng"
            },
            en: {
                "nama": "Meat Sour 35 Gram Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Meat Tamarind 35 Grams from the Cap Gunung brand brings unique innovation to the culinary world with an additional seed content of 3 to 5%. This 35 gram packaging provides convenience for daily use, while the presence of seeds provides a deeper flavor dimension to your dishes. The Cap Gunung brand again shows their high commitment to quality by presenting this Asam Meat, which not only features optimally maintained acidity but also provides a more colorful culinary experience.
                \u00a0\u00a0\u00a0\u00a0 With 35 Gram Meat Tamarind from the Cap Gunung brand, you can create dishes that are not only delicious but also captivate your taste buds with the unique content of the seeds being preserved as well as possible. The content of these seeds gives a special touch to every mouthful, creating an unforgettable taste sensation. Explore a variety of new flavors and enjoy creativity in cooking with Asam Meat 35 Gram, an innovation from the Cap Gunung Brand to perfect your culinary experience.
                `,
                "kategori": "daging",
                "image": gambar22,
                "ukuran": 35,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 26.29/dus",
                "isi": "Fill",
                "total_isi": "30 Pack",
                "pack": "1 Pack",
                "total_pack": "12 plans"
            },
            ar: {
                "nama": "لحم حامض 35 جرام ختم جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم التمر الهندي 35 جرامًا من العلامة التجارية Cap Gunung يجلب ابتكارًا فريدًا إلى عالم الطهي مع محتوى بذور إضافي يتراوح من 3 إلى 5٪. توفر هذه العبوة التي يبلغ وزنها 35 جرامًا الراحة للاستخدام اليومي، بينما يوفر وجود البذور بُعدًا أعمق للنكهة لأطباقك. تُظهر العلامة التجارية Cap Gunung مرة أخرى التزامها العالي بالجودة من خلال تقديم لحوم Asam، التي لا تتميز فقط بالحموضة المثالية ولكنها توفر أيضًا تجربة طهي أكثر تنوعًا.
                \u00a0\u00a0\u00a0\u00a0 مع 35 جرامًا من لحم التمر الهندي من ماركة Cap Gunung، يمكنك إعداد أطباق ليست لذيذة فحسب، بل أيضًا تأسر ذوقك من خلال المحتوى الفريد للبذور التي يتم الحفاظ عليها قدر الإمكان. يمنح محتوى هذه البذور لمسة خاصة لكل لقمة، مما يخلق إحساسًا بالطعم لا يُنسى. اكتشف مجموعة متنوعة من النكهات الجديدة واستمتع بالإبداع في الطبخ مع لحم Asam 35 جرام، وهو ابتكار من العلامة التجارية Cap Gunung لتحسين تجربة الطهي الخاصة بك.
                `,
                "kategori": "daging",
                "image": gambar22,
                "ukuran": 35,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 96.54/هكذا",
                "isi": "يملأ",
                "total_isi": "30 عبوة",
                "pack": "1 حزمة",
                "total_pack": "12 خطط"
            },
            zh: {
                "nama": "酸肉 35 克邮票 Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 肉罗望子 35 克为烹饪界带来了独特的创新，额外添加了 3% 至 5% 的种子含量。 这种 35 克的包装为日常使用提供了便利，而种子的存在为您的菜肴提供了更深的风味。 Cap Gunung 品牌再次展示了他们对品质的高度承诺，推出了这款阿三肉，它不仅具有最佳的酸度，而且还提供了更加丰富多彩的烹饪体验。
                \u00a0\u00a0\u00a0\u00a0 使用 Cap Gunung 品牌的 35 克肉罗望子，您可以制作出不仅美味，而且可以尽可能保留种子独特含量的菜肴，从而吸引您的味蕾。 这些种子的成分给每一口带来特殊的触感，创造令人难忘的味觉。 使用阿萨姆肉 35 克探索各种新口味并享受烹饪创意，这是 Cap Gunung 品牌的一项创新，旨在完善您的烹饪体验。
                `,
                "kategori": "daging",
                "image": gambar22,
                "ukuran": 35,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 187.33/杜斯",
                "isi": "内容",
                "total_isi": "30 包",
                "pack": "1 包",
                "total_pack": "12个计划"
            }
        },
        {
            id: {
                "nama": "Asam Daging 75 Gram Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 75 Gram dari brand Cap Gunung membawa kelezatan asam yang unik dengan tambahan kandungan biji sebesar 3 sampai 5%. Dikemas dengan praktis dalam berat 75 gram, produk ini tidak hanya memberikan solusi cepat dalam penggunaan sehari-hari, tetapi juga memberikan keberagaman rasa pada hidangan Anda. Brand Cap Gunung, yang selalu dikenal akan komitmennya terhadap standar kualitas tinggi, memastikan bahwa Asam Daging ini tidak hanya menawarkan keasaman yang optimal, tetapi juga karakter khas dari kandungan biji, menciptakan pengalaman kuliner yang khas dan berkesan.
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 75 Gram dari brand Cap Gunung menjadi pilihan yang memikat dengan kandungan biji sebesar 3 sampai 5%, memberikan sentuhan spesial pada setiap suapan. Kemasan praktis ini tidak hanya memberikan kemudahan dalam penyajian, tetapi juga memungkinkan Anda menikmati kelezatan asam dalam berbagai hidangan. Dengan Asam Daging Cap Gunung, Anda membawa nuansa eksotis ke dalam dapur Anda, menciptakan momen kuliner yang penuh kelezatan dan tak terlupakan.
                `,
                "kategori": "daging",
                "image": gambar1,
                "ukuran": 75,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 514.000/dus",
                "isi": "Isi",
                "total_isi": "160 Pack",
            },
            en: {
                "nama": "Meat Sour 75 Gram Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Meat 75 Grams from the Cap Gunung brand brings unique sour deliciousness with an additional seed content of 3 to 5%. Conveniently packaged in a weight of 75 grams, this product not only provides a quick solution for daily use, but also provides a variety of flavors to your dishes. The Cap Gunung brand, which has always been known for its commitment to high quality standards, ensures that this Asam Meat not only offers optimal acidity, but also the distinctive character of the bean content, creating a distinctive and memorable culinary experience.
                \u00a0\u00a0\u00a0\u00a0 Tamarind Meat 75 Gram from the Cap Gunung brand is an attractive choice with a seed content of 3 to 5%, giving a special touch to every bite. This practical packaging not only makes it easy to serve, but also allows you to enjoy the delicious sourness in various dishes. With Asam Meat Cap Gunung, you bring an exotic feel into your kitchen, creating delicious and unforgettable culinary moments.
                `,
                "kategori": "daging",
                "image": gambar1,
                "ukuran": 75,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 33.36/dus",
                "isi": "Contents",
                "total_isi": "160 Packs",
            },
            ar: {
                "nama": "لحم حامض 75 جرام ختم جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم Asam 75 جرامًا من العلامة التجارية Cap Gunung يقدم طعمًا حامضًا فريدًا مع محتوى بذور إضافي يتراوح من 3 إلى 5٪. تم تعبئته بشكل مريح بوزن 75 جرامًا، هذا المنتج لا يوفر حلاً سريعًا للاستخدام اليومي فحسب، بل يوفر أيضًا مجموعة متنوعة من النكهات لأطباقك. تضمن العلامة التجارية Cap Gunung، التي اشتهرت دائمًا بالتزامها بمعايير الجودة العالية، أن لحم Asam هذا لا يوفر الحموضة المثالية فحسب، بل يوفر أيضًا الطابع المميز لمحتوى الفول، مما يخلق تجربة طهي فريدة لا تُنسى.
                \u00a0\u00a0\u00a0\u00a0 لحم أسام 75 جرام من ماركة كاب جونونج هو خيار جذاب مع محتوى بذور يتراوح من 3 إلى 5%، مما يعطي لمسة خاصة لكل قضمة. هذه العبوة العملية لا تجعل من السهل تقديمها فحسب، بل تسمح لك أيضًا بالاستمتاع بالطعم الحامض اللذيذ في أطباق مختلفة. مع Asam Meat Cap Gunung، ستضفي إحساسًا غريبًا على مطبخك، مما يخلق لحظات طهي لذيذة لا تُنسى.
                `,
                "kategori": "daging",
                "image": gambar1,
                "ukuran": 75,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 122.53/هكذا",
                "isi": "محتويات",
                "total_isi": "160 عبوة",
            },
            zh: {
                "nama": "酸肉 75 克邮票 Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿参肉 75 克带来独特的酸味美味，并额外添加 3 至 5% 的种子含量。 该产品采用方便的包装，重量为 75 克，不仅为日常使用提供快速解决方案，而且还为您的菜肴提供多种口味。 Cap Gunung 品牌一直以致力于高质量标准而闻名，确保阿萨姆肉不仅提供最佳的酸度，而且还具有豆类成分的独特特征，创造独特而难忘的烹饪体验。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿萨姆肉 75 克是一个有吸引力的选择，种子含量为 3 至 5%，每一口都有特殊的触感。 这种实用的包装不仅方便食用，还可以让您在各种菜肴中享受美味的酸味。 使用 Asam Meat Cap Gunung，您可以为您的厨房带来异国情调，创造美味和难忘的烹饪时刻。
                `,
                "kategori": "daging",
                "image": gambar1,
                "ukuran": 75,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 237.74/杜斯",
                "isi": "内容",
                "total_isi": "160 包",
            },
        },
        {
            id: {
                "nama": "Asam Daging 100 Gram Baru Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 100 Gram Baru dari brand Cap Gunung merupakan pilihan ideal untuk Anda yang mencari bumbu berkualitas dalam kemasan praktis. Dengan berat 100 gram, produk ini memudahkan Anda dalam mengatur takaran bumbu sesuai selera. Keistimewaan Asam Daging ini terletak pada keberadaan kandungan biji yang tetap dipertahankan, mencapai sebesar 3 sampai 5%. Meskipun dalam kemasan yang lebih kecil, produk ini menghadirkan cita rasa autentik yang menjadi ciri khas dari Cap Gunung.
                \u00a0\u00a0\u00a0\u00a0 Kandungan biji yang terjaga dalam Asam Daging 100 Gram Baru tidak hanya memberikan rasa asam yang lezat, tetapi juga menambahkan karakteristik tekstur yang unik pada hidangan Anda. Dengan ukuran yang kompak, produk ini memungkinkan Anda untuk dengan mudah mengeksplorasi dan menyempurnakan setiap hidangan, menciptakan pengalaman kuliner yang memuaskan dengan sentuhan khas Cap Gunung.
                `,
                "kategori": "daging",
                "image": gambar2,
                "ukuran": 100,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 548.000/dus",
                "isi": "Isi",
                "total_isi": "120 Pack",
            },
            en: {
                "nama": "Meat Tamarind 100 Grams New Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 The New 100 Gram Meat Tamarind from the Cap Gunung brand is the ideal choice for those of you who are looking for quality spices in practical packaging. Weighing 100 grams, this product makes it easy for you to adjust the spice dosage according to taste. The specialty of this Meat Acid lies in the fact that the seed content is maintained, reaching 3 to 5%. Even though it is in a smaller package, this product presents the authentic taste that is the hallmark of Cap Gunung.
                \u00a0\u00a0\u00a0\u00a0 The preserved seed content in the New 100 Gram Meat Tamarind not only provides a delicious sour taste, but also adds unique textural characteristics to your dishes. With its compact size, this product allows you to easily explore and perfect each dish, creating a satisfying culinary experience with a signature Cap Gunung touch.
                `,
                "kategori": "daging",
                "image": gambar2,
                "ukuran": 100,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 35.57/dus",
                "isi": "Fill",
                "total_isi": "120 Pack",
            },
            ar: {
                "nama": "لحم تمر هندي 100 جرام نيو كاب جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 يعتبر التمر الهندي الجديد 100 جرام من العلامة التجارية Cap Gunung هو الخيار المثالي لأولئك منكم الذين يبحثون عن توابل عالية الجودة في عبوات عملية. يزن هذا المنتج 100 جرام، مما يسهل عليك ضبط جرعة التوابل حسب الذوق. تكمن خصوصية حمض اللحوم في الحفاظ على محتوى البذور، حيث يصل إلى 3 إلى 5%. على الرغم من أنه في عبوة أصغر، إلا أن هذا المنتج يقدم المذاق الأصيل الذي يمثل السمة المميزة لكاب جونونج.
                \u00a0\u00a0\u00a0\u00a0 محتوى البذور المحفوظ في 100 جرام لحم التمر الهندي الجديد لا يوفر طعمًا حامضًا لذيذًا فحسب، بل يضيف أيضًا خصائص تركيبية فريدة إلى أطباقك. بفضل حجمه الصغير، يتيح لك هذا المنتج استكشاف كل طبق وتحسينه بسهولة، مما يخلق تجربة طهي مرضية مع لمسة Cap Gunung المميزة.
                `,
                "kategori": "daging",
                "image": gambar2,
                "ukuran": 100,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 130.63/هكذا",
                "isi": "يملأ",
                "total_isi": "120 عبوة",
            },
            zh: {
                "nama": "肉罗望子 100 克 New Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的新款 100 克肉罗望子是那些正在寻找实用包装的优质香料的人们的理想选择。 该产品重100克，让您可以根据口味轻松调整香料用量。 这种肉酸的特点在于保持了种子含量，达到3%~5%。 尽管包装较小，但该产品呈现出 Cap Gunung 标志性的正宗味道。
                \u00a0\u00a0\u00a0\u00a0 新 100 克肉罗望子中保留的种子成分不仅提供美味的酸味，而且还为您的菜肴增添独特的质地特征。 该产品尺寸紧凑，可让您轻松探索和完善每道菜，通过 Cap Gunung 标志性的风格创造令人满意的烹饪体验。
                `,
                "kategori": "daging",
                "image": gambar2,
                "ukuran": 100,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 253.47/杜斯",
                "isi": "内容",
                "total_isi": "120 包",
            },
        },
        {
            id: {
                "nama": "Asam Daging 150 Gram Baru Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 150 Gram Baru dari brand Cap Gunung memperkenalkan sentuhan segar dalam pengalaman rasa Anda, dengan tambahan kandungan biji sebesar 3 sampai 5%. Dikemas dalam kemasan yang praktis dengan berat 150 gram, produk ini menghadirkan kesempatan untuk menjelajahi berbagai resep kuliner dengan keunikan rasa yang lebih mendalam. Brand Cap Gunung, yang terkenal akan dedikasinya terhadap standar kualitas tinggi, menyuguhkan Asam Daging ini dengan keasaman yang optimal, sambil mempertahankan keberadaan biji yang menonjolkan karakter khas pada setiap suapan.
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 150 Gram Baru dari brand Cap Gunung menjadi opsi yang menggoda untuk menghadirkan nuansa eksklusif pada hidangan Anda. Dengan kandungan biji sebesar 3 sampai 5%, produk ini menawarkan kesempatan untuk menciptakan hidangan dengan rasa asam yang istimewa dan tak terlupakan. Dengan Asam Daging Cap Gunung, Anda tidak hanya memperkaya pengalaman kuliner Anda, tetapi juga menciptakan momen kuliner yang ceria dan penuh inovasi, menjadikan setiap hidangan sebagai karya seni rasa yang memikat selera.
                `,
                "kategori": "daging",
                "image": gambar3,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 608.500/dus",
                "isi": "Isi",
                "total_isi": "100 Pack",
            },
            en: {
                "nama": "Meat Tamarind 150 Grams New Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind Meat 150 Gram New from the Cap Gunung brand introduces a fresh touch to your taste experience, with an additional seed content of 3 to 5%. Packaged in practical packaging weighing 150 grams, this product presents the opportunity to explore various culinary recipes with unique, deeper flavors. The Cap Gunung brand, which is known for its dedication to high quality standards, presents this Asam Meat with optimal acidity, while maintaining the presence of seeds that accentuate the distinctive character of each bite.
                \u00a0\u00a0\u00a0\u00a0 The New 150 Gram Meat Tamarind from the Cap Gunung brand is a tempting option to bring an exclusive feel to your dishes. With a seed content of 3 to 5%, this product offers the opportunity to create dishes with a special and unforgettable sour taste. With Asam Meat Cap Gunung, you not only enrich your culinary experience, but also create culinary moments that are cheerful and full of innovation, making every dish a work of art of taste that captivates the taste buds.
                `,
                "kategori": "daging",
                "image": gambar3,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 39.50/dus",
                "isi": "Contents",
                "total_isi": "100 Packs",
            },
            ar: {
                "nama": "لحم تمر هندي 150 جرام نيو كاب جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم تمر هندي 150 جرام جديد من ماركة Cap Gunung يقدم لمسة جديدة لتجربة ذوقك، مع محتوى بذور إضافي بنسبة 3 إلى 5%. معبأ في عبوة عملية تزن 150 جرامًا، يقدم هذا المنتج الفرصة لاستكشاف وصفات طهي متنوعة بنكهات فريدة وأعمق. تقدم العلامة التجارية Cap Gunung، المعروفة بالتزامها بمعايير الجودة العالية، لحم Asam هذا مع الحموضة المثالية، مع الحفاظ على وجود البذور التي تبرز الطابع المميز لكل قضمة.
                \u00a0\u00a0\u00a0\u00a0 يعد لحم التمر الهندي الجديد 150 جرام من ماركة Cap Gunung خيارًا مغريًا لإضفاء لمسة حصرية على أطباقك. مع محتوى البذور من 3 إلى 5%، يوفر هذا المنتج الفرصة لإعداد أطباق ذات طعم حامض خاص لا ينسى. مع Asam Meat Cap Gunung، فإنك لا تثري تجربتك في الطهي فحسب، بل تخلق أيضًا لحظات طهي مبهجة ومليئة بالابتكار، مما يجعل كل طبق عملاً فنيًا من الذوق الذي يأسر براعم التذوق.
                `,
                "kategori": "daging",
                "image": gambar3,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 145.06/هكذا",
                "isi": "محتويات",
                "total_isi": "100 عبوة",
            },
            zh: {
                "nama": "肉罗望子 150 克 New Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 罗望子肉 150 克 Cap Gunung 品牌的新品为您的味觉体验带来新鲜感，额外添加了 3% 至 5% 的种子含量。 该产品采用重 150 克的实用包装，提供了探索具有独特、更深层次风味的各种烹饪食谱的机会。 Cap Gunung 品牌以追求高品质标准而闻名，为阿萨姆肉提供最佳的酸度，同时保持种子的存在，突出每一口的独特特征。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的新款 150 克肉罗望子是一个诱人的选择，可为您的菜肴带来独特的感觉。 该产品的种子含量为 3% 至 5%，可以用来制作具有特殊且令人难忘的酸味的菜肴。 有了Asam Meat Cap Gunung，您不仅丰富了您的烹饪体验，还创造了欢快而充满创新的烹饪时刻，让每一道菜都成为俘获味蕾的味觉艺术品。
                `,
                "kategori": "daging",
                "image": gambar3,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 281.45/杜斯",
                "isi": "内容",
                "total_isi": "100 包",
            },
        },
        {
            id: {
                "nama": "Asam Daging 150 Gram Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 150 Gram dari brand Cap Gunung menghadirkan kelezatan asam yang menggoda dengan sentuhan khas dari kandungan biji sebesar 3 sampai 5%. Dikemas dalam kemasan yang praktis, produk ini memungkinkan Anda untuk mengeksplorasi ragam hidangan dengan rasa yang lebih dalam. Brand Cap Gunung, dengan reputasi tinggi dalam menjaga standar kualitas, menawarkan Asam Daging ini dengan keasaman yang optimal, sementara keberadaan biji memberikan dimensi rasa yang khas pada setiap sajian.
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 150 Gram dari brand Cap Gunung menjadi pilihan yang sempurna bagi pecinta kuliner yang menghargai keunikan rasa. Kandungan biji sebesar 3 sampai 5% menjadi ciri khas yang menonjol, memberikan nuansa rasa yang khas pada setiap hidangan. Dengan Asam Daging Cap Gunung, Anda tidak hanya menciptakan hidangan lezat, tetapi juga merangkul pengalaman kuliner yang memuaskan dan tak terlupakan, menjadikan setiap santapan sebagai perjalanan rasa yang memikat dan menggugah selera.
                `,
                "kategori": "daging",
                "image": gambar4,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "favorite",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 456.500/dus",
                "isi": "Isi",
                "total_isi": "100 Pack",
            },
            en: {
                "nama": "Meat Sour 150 Grams Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Meat 150 Gram from the Cap Gunung brand presents a tempting sour delicacy with a distinctive touch from a seed content of 3 to 5%. Packaged in practical packaging, this product allows you to explore a variety of dishes with deeper flavors. The Cap Gunung brand, with a high reputation for maintaining quality standards, offers this Asam Meat with optimal acidity, while the presence of seeds provides a distinctive flavor dimension to each dish.
                \u00a0\u00a0\u00a0\u00a0 Asam Meat 150 Gram from the Cap Gunung brand is the perfect choice for culinary lovers who appreciate unique taste. The seed content of 3 to 5% is a prominent characteristic, providing a distinctive flavor nuance to each dish. With Asam Meat Cap Gunung, you not only create delicious dishes, but also embrace a satisfying and unforgettable culinary experience, making every meal an enthralling and mouth-watering journey of flavors.
                `,
                "kategori": "daging",
                "image": gambar4,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "favorite",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 29.63/dus",
                "isi": "Contents",
                "total_isi": "100 Packs",
            },
            ar: {
                "nama": "لحم حامض 150 جرام ختم جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم أسام 150 جرام من ماركة كاب جونونج يقدم طعمًا حامضًا مغريًا مع لمسة مميزة من محتوى البذور من 3 إلى 5٪. يتيح لك هذا المنتج المعبأ في عبوة عملية استكشاف مجموعة متنوعة من الأطباق بنكهات أعمق. العلامة التجارية Cap Gunung، ذات السمعة العالية في الحفاظ على معايير الجودة، تقدم لحم Asam هذا مع الحموضة المثالية، بينما يوفر وجود البذور بُعدًا مميزًا للنكهة لكل طبق.
                \u00a0\u00a0\u00a0\u00a0 لحم Asam 150 جرام من ماركة Cap Gunung هو الخيار الأمثل لمحبي الطهي الذين يقدرون المذاق الفريد. يعد محتوى البذور من 3 إلى 5% سمة بارزة، مما يوفر نكهة مميزة لكل طبق. مع Asam Meat Cap Gunung، لا تقوم بإعداد أطباق لذيذة فحسب، بل تحتضن أيضًا تجربة طهي مرضية لا تُنسى، مما يجعل كل وجبة رحلة آسرة وشهية من النكهات.
                `,
                "kategori": "daging",
                "image": gambar4,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "favorite",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 108.82/هكذا",
                "isi": "محتويات",
                "total_isi": "100 عبوة",
            },
            zh: {
                "nama": "酸肉 150 克邮票 Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 150 克阿参肉呈现出诱人的酸味，其种子含量为 3 至 5%，具有独特的口感。 该产品采用实用的包装，让您探索各种口味更深层次的菜肴。 Cap Gunung 品牌在保持质量标准方面享有盛誉，为阿萨姆肉提供最佳的酸度，而种子的存在为每道菜提供了独特的风味。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿参肉 150 克是追求独特口味的烹饪爱好者的完美选择。 3% 至 5% 的种子含量是一个显着特征，为每道菜提供了独特的风味细微差别。 有了 Asam Meat Cap Gunung，您不仅可以制作出美味佳肴，还可以享受令人满意且难忘的烹饪体验，让每一餐都是令人着迷、令人垂涎欲滴的味觉之旅。
                `,
                "kategori": "daging",
                "image": gambar4,
                "ukuran": 150,
                "brand": "gunung",
                "versi": "favorite",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 211.15/杜斯",
                "isi": "内容",
                "total_isi": "100 包",
            },
        },
        {
            id: {
                "nama": "Asam Daging 250 Gram Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 250 Gram dari brand Cap Gunung adalah pilihan sempurna untuk menikmati kelezatan asam dengan sentuhan khas kandungan biji sebesar 3 sampai 5%. Kemasan praktis berat 250 gram membuatnya ideal untuk kebutuhan dapur sehari-hari, memberikan fleksibilitas dalam menyajikan hidangan dengan rasa yang lebih dalam. Brand Cap Gunung, yang dikenal dengan dedikasinya terhadap standar kualitas tinggi, memastikan Asam Daging ini hadir dengan keasaman yang optimal, sementara kandungan biji memberikan aroma dan rasa yang khas, menghadirkan pengalaman kuliner yang tak terlupakan.
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 250 Gram dari brand Cap Gunung menghadirkan pilihan istimewa bagi para pencinta masakan asam yang menginginkan kualitas tinggi. Kandungan biji sebesar 3 sampai 5% menjadi identitas yang membedakan, menciptakan dimensi rasa yang istimewa dan berkelas pada setiap sajian. Dengan Asam Daging Cap Gunung, Anda tidak hanya membawa kelezatan asam ke meja makan Anda, tetapi juga mengundang keberagaman rasa dalam hidangan sehari-hari, menciptakan pengalaman kuliner yang memuaskan dan memikat selera.
                `,
                "kategori": "daging",
                "image": gambar5,
                // "image": [gambar5, gambar52, gambar53, gambar54],
                "ukuran": 250,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 507.000/dus",
                "isi": "Isi",
                "total_isi": "60 Pack",
            },
            en: {
                "nama": "Meat Sour 250 Grams Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Meat 250 Gram from the Cap Gunung brand is the perfect choice to enjoy delicious tamarind with a distinctive touch of seed content of 3 to 5%. The practical packaging weighs 250 grams making it ideal for everyday kitchen needs, providing flexibility in serving dishes with deeper flavors. The Cap Gunung brand, which is known for its dedication to high quality standards, ensures that this Asam Meat comes with optimal acidity, while the seed content provides a distinctive aroma and taste, providing an unforgettable culinary experience.
                \u00a0\u00a0\u00a0\u00a0 Asam Meat 250 Gram from the Cap Gunung brand presents a special choice for lovers of sour dishes who want high quality. The seed content of 3 to 5% is a distinguishing identity, creating a special and classy flavor dimension in each dish. With Asam Meat Cap Gunung, you not only bring sour deliciousness to your dining table, but also invite a diversity of flavors in everyday dishes, creating a culinary experience that satisfies and captivates the taste buds.
                `,
                "kategori": "daging",
                "image": gambar5,
                // "image": [gambar5, gambar52, gambar53, gambar54],
                "ukuran": 250,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 32.91/dus",
                "isi": "Contents",
                "total_isi": "60 Packs",
            },
            ar: {
                "nama": "لحم حامض 250 جرام ختم جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم آسام 250 جرام من ماركة كاب جونونج هو الخيار الأمثل للاستمتاع بالتمر الهندي اللذيذ مع لمسة مميزة من محتوى البذور من 3 إلى 5٪. تزن العبوة العملية 250 جرامًا مما يجعلها مثالية لاحتياجات المطبخ اليومية، مما يوفر المرونة في تقديم الأطباق بنكهات أعمق. تضمن العلامة التجارية Cap Gunung، المعروفة بالتزامها بمعايير الجودة العالية، أن يأتي لحم Asam هذا مع الحموضة المثالية، بينما يوفر محتوى البذور رائحة وطعمًا مميزين، مما يوفر تجربة طهي لا تُنسى.
                \u00a0\u00a0\u00a0\u00a0 لحم Asam 250 جرام من ماركة Cap Gunung يقدم خيارًا خاصًا لمحبي الأطباق الحامضة الذين يريدون الجودة العالية. يعتبر محتوى البذور من 3 إلى 5% هوية مميزة، مما يخلق بعدًا خاصًا وراقيًا للنكهة في كل طبق. مع Asam Meat Cap Gunung، فإنك لا تجلب فقط الطعم الحامض إلى طاولة الطعام الخاصة بك، ولكن أيضًا تدعو مجموعة متنوعة من النكهات إلى الأطباق اليومية، مما يخلق تجربة طهي ترضي وتأسر براعم التذوق.
                `,
                "kategori": "daging",
                "image": gambar5,
                // "image": [gambar5, gambar52, gambar53, gambar54],
                "ukuran": 250,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 120.86/هكذا",
                "isi": "محتويات",
                "total_isi": "60 عبوة",
            },
            zh: {
                "nama": "酸肉 250 克邮票 Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿萨姆肉 250 克是享受美味罗望子的完美选择，其独特的种子含量为 3 至 5%。 实用的包装重 250 克，非常适合日常厨房需求，可以灵活地提供口味更深的菜肴。 Cap Gunung 品牌以追求高品质标准而闻名，确保阿萨姆肉具有最佳的酸度，而种子成分则提供独特的香气和味道，提供难忘的烹饪体验。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿参肉 250 克为追求高品质的酸菜爱好者提供了特殊的选择。 3% 至 5% 的种子含量是一个独特的特征，为每道菜创造出特殊而优雅的风味维度。 有了Asam Meat Cap Gunung，您不仅可以将酸酸的美味带到您的餐桌上，还可以在日常菜肴中引入多种风味，创造出满足和吸引味蕾的烹饪体验。
                `,
                "kategori": "daging",
                "image": gambar5,
                // "image": [gambar5, gambar52, gambar53, gambar54],
                "ukuran": 250,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 234.50/杜斯",
                "isi": "内容",
                "total_isi": "60 包",
            },
        },
        // {
        //     id: {
        //         "nama": "Asam Daging 250 Gram Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Daging 250 Gram dari brand Cap Gunung menawarkan kelezatan asam yang unik dengan sentuhan khas kandungan biji sebesar 3 sampai 5%. Dikemas dalam kemasan berat 250 gram, produk ini menjadi pilihan praktis untuk mengeksplorasi berbagai resep kuliner dengan rasa yang lebih dalam. Brand Cap Gunung, yang dikenal akan komitmen tinggi terhadap standar kualitas, memastikan Asam Daging ini tidak hanya memberikan keasaman yang optimal, tetapi juga karakter khas dari kandungan biji, menciptakan pengalaman kuliner yang istimewa dan tak terlupakan.
        //         \u00a0\u00a0\u00a0\u00a0 Asam Daging 250 Gram dari brand Cap Gunung membuka peluang untuk menghadirkan keberagaman rasa dalam hidangan sehari-hari. Kandungan biji sebesar 3 sampai 5% menjadi ciri khas yang mengundang untuk dinikmati, memberikan nuansa rasa yang khas pada setiap suapan. Dengan Asam Daging Cap Gunung, Anda tidak hanya menyajikan hidangan asam yang lezat, tetapi juga menciptakan momen kuliner yang penuh inovasi, mengubah setiap santapan menjadi pengalaman kuliner yang memikat dan menggugah selera.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar6,
        //         "ukuran": 250,
        //         "brand": "gunung"
        //     },
        //     en: {
        //         "nama": "Meat Sour 250 Gram Brand Mountain",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Meat 250 Gram from the Cap Gunung brand offers a unique sour delicacy with a distinctive touch of seed content of 3 to 5%. Packaged in packages weighing 250 grams, this product is a practical choice for exploring various culinary recipes with deeper flavors. The Cap Gunung brand, which is known for its high commitment to quality standards, ensures that Meat Asam not only provides optimal acidity, but also the distinctive character of the bean content, creating a special and unforgettable culinary experience.
        //         \u00a0\u00a0\u00a0\u00a0 250 Gram Meat Tamarind from the Cap Gunung brand opens up opportunities to present a diversity of flavors in everyday dishes. The seed content of 3 to 5% is a characteristic that is inviting to enjoy, giving a distinctive taste nuance to every mouthful. With Cap Gunung Asam Meat, you not only serve delicious tamarind dishes, but also create culinary moments full of innovation, turning every meal into an alluring and appetizing culinary experience.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar6,
        //         "ukuran": 250,
        //         "brand": "gunung"
        //     },
        //     ar: {
        //         "nama": "لحم حامض 250 جرام بانج بانج كاب جونونج",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Meat 250 Gram من العلامة التجارية Cap Gunung تقدم نكهة حامضة فريدة من نوعها مع لمسة مميزة من محتوى البذور بنسبة 3 إلى 5٪. يعد هذا المنتج، المعبأ في عبوات تزن 250 جرامًا، خيارًا عمليًا لاستكشاف وصفات الطهي المختلفة بنكهات أعمق. تضمن العلامة التجارية Cap Gunung، المعروفة بالتزامها العالي بمعايير الجودة، أن Meat Asam لا توفر الحموضة المثالية فحسب، بل توفر أيضًا الطابع المميز لمحتوى الفول، مما يخلق تجربة طهي خاصة لا تُنسى.
        //         \u00a0\u00a0\u00a0\u00a0 250 Gram Meat Tamarind من العلامة التجارية Cap Gunung يفتح الفرص لتقديم مجموعة متنوعة من النكهات في الأطباق اليومية. يعتبر محتوى البذور من 3 إلى 5% من الخصائص الجذابة للاستمتاع بها، مما يمنح طعمًا مميزًا لكل لقمة. مع Cap Gunung Asam Meat، فإنك لا تقدم أطباق التمر الهندي اللذيذة فحسب، بل تخلق أيضًا لحظات طهي مليئة بالابتكار، وتحول كل وجبة إلى تجربة طهي مغرية وشهية.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar6,
        //         "ukuran": 250,
        //         "brand": "gunung"
        //     },
        //     zh: {
        //         "nama": "酸肉 250 克  Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿萨姆肉 250 克  提供独特的酸味美味，种子含量为 3 至 5%。 该产品采用重 250 克的包装，是探索口味更深的各种烹饪食谱的实用选择。 Cap Gunung 品牌以其对质量标准的高度承诺而闻名，确保 Meat Asam 不仅提供最佳的酸度，而且还具有豆类成分的独特特征，创造出特殊而难忘的烹饪体验。
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 肉罗望子 250 克  为在日常菜肴中呈现多样化风味提供了机会。 3% 至 5% 的种子含量是令人愉悦的特征，为每一口带来独特的细微差别。 在 Cap Gunung Asam Meat，您不仅可以享用美味的罗望子菜肴，还可以创造充满创新的烹饪时刻，将每一顿饭都变成诱人而开胃的烹饪体验。
        //         `,
        //         "kategori": "daging",
        //         "image": gambar6,
        //         "ukuran": 250,
        //         "brand": "gunung"
        //     },
        // },
        // {
        //     id: {
        //         "nama": "Asam Daging 250 Gram Baru Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Daging 250 Gram Baru dari brand Cap Gunung adalah pilihan yang tepat untuk menambahkan sentuhan asam istimewa pada setiap hidangan Anda. Dengan kemasan berukuran 250 gram, produk ini menawarkan kualitas terbaik yang dijamin oleh reputasi Cap Gunung. Keunggulan Asam Daging ini terletak pada keberadaan kandungan biji yang masih dipertahankan, dengan persentase mencapai 3 sampai 5%. Meskipun dalam kemasan yang lebih kecil, produk ini memberikan pengalaman kuliner yang autentik dan kaya rasa.
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung memberikan sentuhan inovatif pada Asam Daging 250 Gram Baru dengan mempertahankan kandungan biji, menciptakan dimensi baru dalam dunia masakan. Rasa asam yang khas dan tekstur yang unik dari biji memberikan karakteristik tersendiri pada setiap hidangan. Dengan ukuran yang sesuai, produk ini memungkinkan Anda untuk dengan mudah menghadirkan kelezatan khas Cap Gunung dalam berbagai kreasi kuliner, menciptakan pengalaman gastronomi yang memuaskan.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar23,
        //         "ukuran": 250,
        //         "brand": "gunung",
        //         "versi": "baru"
        //     },
        //     en: {
        //         "nama": "Meat Tamarind 250 Gram New Brand Mountain",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Meat 250 Gram Baru from the Cap Gunung brand is the right choice to add a special sour touch to each of your dishes. With a 250 gram packaging, this product offers the best quality guaranteed by Cap Gunung's reputation. The advantage of this Meat Acid lies in the presence of seed content which is still maintained, with a percentage reaching 3 to 5%. Even though it is in a smaller package, this product provides an authentic and flavorful culinary experience.
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung provides an innovative touch to the Baru 250 Gram Meat Tamarind by retaining the seed content, creating a new dimension in the world of cooking. The distinctive sour taste and unique texture of the seeds give each dish its own characteristics. With the right size, this product allows you to easily present Cap Gunung's signature delights in various culinary creations, creating a satisfying gastronomic experience.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar23,
        //         "ukuran": 250,
        //         "brand": "gunung",
        //         "versi": "baru"
        //     },
        //     ar: {
        //         "nama": "لحم تمر هندي 250 جرام نيو بانج بانج كاب جونونج",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Meat 250 Gram Baru من ماركة Cap Gunung هو الخيار الصحيح لإضافة لمسة حامضة خاصة إلى كل طبق من أطباقك. مع عبوة 250 جرام، يقدم هذا المنتج أفضل جودة مضمونة بسمعة Cap Gunung. وتكمن ميزة هذا الحمض في وجود محتوى البذور فيه والذي لا يزال محتفظاً به بنسبة تصل إلى 3 إلى 5%. على الرغم من أنه في عبوة أصغر، إلا أن هذا المنتج يوفر تجربة طهي أصيلة ولذيذة.
        //         \u00a0\u00a0\u00a0\u00a0 يوفر Cap Gunung لمسة مبتكرة لـ Baru 250 Gram Meat Tamarind من خلال الاحتفاظ بمحتوى البذور، مما يخلق بعدًا جديدًا في عالم الطبخ. الطعم الحامض المميز والملمس الفريد للبذور يمنح كل طبق خصائصه الخاصة. مع الحجم المناسب، يتيح لك هذا المنتج تقديم مأكولات Cap Gunung المميزة بسهولة في إبداعات الطهي المختلفة، مما يخلق تجربة تذوق مرضية.
        //         `,
        //         "kategori": "daging",
        //         "image": gambar23,
        //         "ukuran": 250,
        //         "brand": "gunung",
        //         "versi": "baru"
        //     },
        //     zh: {
        //         "nama": "肉罗望子 250 克新 Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 阿参肉 250 克 Baru 是为您的每道菜添加特殊酸味的正确选择。 该产品采用 250 克包装，提供 Cap Gunung 声誉所保证的最佳质量。 这种肉酸的优点在于仍然保留了种子含量，百分比达到3%至5%。 尽管包装较小，但该产品提供了正宗美味的烹饪体验。
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 通过保留种子成分，为 Baru 250 克肉罗望子提供了创新的触感，在烹饪世界中创造了一个新的维度。 种子独特的酸味和独特的质地使每道菜都有自己的特色。 凭借合适的尺寸，该产品可让您轻松地在各种烹饪创作中呈现 Cap Gunung 的招牌美食，创造令人满意的美食体验。
        //         `,
        //         "kategori": "daging",
        //         "image": gambar23,
        //         "ukuran": 250,
        //         "brand": "gunung",
        //         "versi": "baru"
        //     },
        // },
        {
            id: {
                "nama": "Asam Daging 500 Gram Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 500 Gram dari brand Cap Gunung adalah pilihan sempurna untuk memberikan cita rasa istimewa pada masakan Anda. Dikemas dalam kemasan praktis 500 gram, produk ini menawarkan kualitas terbaik dengan keunikan kandungan biji sebesar 3 sampai 5%.
                \u00a0\u00a0\u00a0\u00a0 Asam Daging Cap Gunung tidak hanya memberikan rasa asam yang khas, tetapi juga tekstur yang unik pada setiap hidangan. Keputusan untuk mempertahankan kandungan biji menciptakan pengalaman kuliner yang lebih beragam dan memuaskan.
                `,
                "kategori": "daging",
                "image": gambar7,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 423.500/dus",
                "isi": "Isi",
                "total_isi": "40 Pack",
            },
            en: {
                "nama": "Meat Sour 500 Grams Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Meat Tamarind 500 Grams from the Cap Gunung brand is the perfect choice to give a special taste to your cooking. Packaged in practical 500 gram packaging, this product offers the best quality with a unique seed content of 3 to 5%.
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung Meat Acid not only provides a distinctive sour taste, but also a unique texture to each dish. The decision to retain the grain content creates a more varied and satisfying culinary experience.
                `,
                "kategori": "daging",
                "image": gambar7,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 27.49/dus",
                "isi": "Contents",
                "total_isi": "40 Packs",
            },
            ar: {
                "nama": "لحم حامض 500 جرام ختم جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم تمر هندي 500 جرام من ماركة كاب جونونج هو الخيار الأمثل لإضفاء مذاق خاص على طبخك. معبأ في عبوة عملية بوزن 500 جرام، يقدم هذا المنتج أفضل جودة مع محتوى فريد من البذور يتراوح من 3 إلى 5%.
                \u00a0\u00a0\u00a0\u00a0 لا يوفر حمض اللحوم Cap Gunung مذاقًا حامضًا مميزًا فحسب، بل يوفر أيضًا ملمسًا فريدًا لكل طبق. إن قرار الاحتفاظ بمحتوى الحبوب يخلق تجربة طهي أكثر تنوعًا وإرضاءً.
                `,
                "kategori": "daging",
                "image": gambar7,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 100.95/هكذا",
                "isi": "محتويات",
                "total_isi": "40 عبوة",
            },
            zh: {
                "nama": "酸肉 500 克邮票 Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 肉罗望子 500 克是为您的烹饪增添特殊味道的完美选择。 该产品采用实用的 500 克包装，提供最佳品质，独特的种子含量为 3 至 5%。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 肉酸不仅提供独特的酸味，而且还为每道菜提供独特的质地。 保留谷物成分的决定创造了更加多样化和令人满意的烹饪体验。
                `,
                "kategori": "daging",
                "image": gambar7,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 195.88/杜斯",
                "isi": "内容",
                "total_isi": "40 包",
            },
        },
        {
            id: {
                "nama": "Asam Daging 1 Kg Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 1 Kg dari brand Cap Gunung adalah pilihan terbaik untuk meningkatkan cita rasa masakan Anda. Dikemas dalam kemasan yang praktis seberat 1 kilogram, produk ini menawarkan kualitas terbaik dengan sentuhan khas dari brand Cap Gunung. Keunikan produk ini terletak pada fakta bahwa asam daging ini masih mempertahankan kandungan biji sebesar 3 sampai 5%, memberikan dimensi tambahan pada pengalaman kuliner Anda.
                \u00a0\u00a0\u00a0\u00a0 Kandungan biji yang dijaga dalam Asam Daging Cap Gunung tidak hanya menciptakan rasa asam yang lezat, tetapi juga menambahkan elemen tekstur yang khas pada setiap sajian. Dengan keputusan ini, Cap Gunung tidak hanya menyajikan produk bumbu biasa, melainkan sebuah inovasi dalam dunia masakan. Dengan Asam Daging 1 Kg ini, Anda dapat dengan mudah menciptakan masakan dengan karakter unik dan autentik yang akan memukau lidah setiap orang.
                `,
                "kategori": "daging",
                "image": gambar8,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 676.000/dus",
                "isi": "Isi",
                "total_isi": "20 Pack",
            },
            en: {
                "nama": "Meat Sour 1 Kg Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Meat Acid 1 Kg from the Cap Gunung brand is the best choice to improve the taste of your cooking. Packaged in practical packaging weighing 1 kilogram, this product offers the best quality with a distinctive touch from the Cap Gunung brand. The uniqueness of this product lies in the fact that it still retains a seed content of 3 to 5%, providing an added dimension to your culinary experience.
                \u00a0\u00a0\u00a0\u00a0 The preserved seed content in Asam Meat Cap Gunung not only creates a delicious sour taste, but also adds a distinctive textural element to each dish. With this decision, Cap Gunung does not only serve ordinary spice products, but rather an innovation in the world of cooking. With this 1 Kg Meat Tamarind, you can easily create dishes with unique and authentic character that will amaze everyone's taste buds.
                `,
                "kategori": "daging",
                "image": gambar8,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 43.88/dus",
                "isi": "Contents",
                "total_isi": "20 Packs",
            },
            ar: {
                "nama": "لحم حامض 1 كيلو كاب جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 حمض اللحوم 1 كجم من ماركة Cap Gunung هو الخيار الأفضل لتحسين مذاق طبخك. معبأ في عبوة عملية تزن 1 كيلوجرام، يقدم هذا المنتج أفضل جودة مع لمسة مميزة من ماركة Cap Gunung. يكمن تفرد هذا المنتج في حقيقة أنه لا يزال يحتفظ بمحتوى البذور بنسبة 3 إلى 5%، مما يوفر بُعدًا إضافيًا لتجربة الطهي الخاصة بك.
                \u00a0\u00a0\u00a0\u00a0 محتوى البذور المحفوظ في Asam Meat Cap Gunung لا يخلق طعمًا حامضًا لذيذًا فحسب، بل يضيف أيضًا عنصرًا تركيبيًا مميزًا لكل طبق. بهذا القرار، لا تقدم شركة كاب جونونج منتجات التوابل العادية فحسب، بل تقدم ابتكارًا في عالم الطبخ. مع 1 كجم من لحم التمر الهندي، يمكنك بسهولة إعداد أطباق ذات طابع فريد وأصيل ستدهش أذواق الجميع.
                `,
                "kategori": "daging",
                "image": gambar8,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 161.15/هكذا",
                "isi": "محتويات",
                "total_isi": "20 عبوة",
            },
            zh: {
                "nama": "酸肉 1 公斤 Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 肉酸 1 Kg 是改善烹饪味道的最佳选择。 该产品采用重 1 公斤的实用包装，提供最佳品质和 Cap Gunung 品牌的独特风格。 该产品的独特之处在于它仍然保留了 3% 至 5% 的种子含量，为您的烹饪体验提供了额外的维度。
                \u00a0\u00a0\u00a0\u00a0 Asam Meat Cap Gunung 中保存的种子成分不仅创造了美味的酸味，还为每道菜增添了独特的质感元素。 通过这一决定，Cap Gunung 不仅仅提供普通的香料产品，而是烹饪领域的一项创新。 使用这 1 公斤肉罗望子，您可以轻松制作出独特而正宗的菜肴，让每个人的味蕾惊叹不已。
                `,
                "kategori": "daging",
                "image": gambar8,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 312.67/杜斯",
                "isi": "内容",
                "total_isi": "20 包",
            },
        },
        {
            id: {
                "nama": "Asam Daging 10 Kg Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 10 Kg dari brand Cap Gunung adalah pilihan utama untuk penggemar masakan yang mencari kualitas unggul dan kepraktisan dalam jumlah besar. Dikemas dalam kemasan besar seberat 10 kilogram, produk ini menunjukkan komitmen brand Cap Gunung dalam menyajikan bahan berkualitas tinggi. Yang membuat Asam Daging ini istimewa adalah keberadaan kandungan biji yang masih dipertahankan sebesar 3 sampai 5%, memberikan nuansa alami pada setiap hidangan.
                \u00a0\u00a0\u00a0\u00a0 Keputusan Cap Gunung untuk mempertahankan kandungan biji dalam Asam Daging 10 Kg menciptakan peluang eksplorasi rasa yang tak terbatas. Biji yang terjaga memberikan dimensi baru dalam pengalaman kuliner, tidak hanya dengan rasa asam yang khas tetapi juga dengan sentuhan tekstur yang unik. Dengan kemasan berukuran besar ini, Cap Gunung menghadirkan solusi praktis bagi para penggemar kuliner yang sering berkreasi dalam jumlah yang lebih besar, memastikan kelezatan dan keberagaman rasa pada setiap masakan.
                `,
                "kategori": "daging",
                "image": gambar9,
                "ukuran": 10000,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",  
                "total": "IDR 540.800/dus",
                "isi": "Isi",
                "total_isi": "20 Pack",
            },
            en: {
                "nama": "Meat Sour 10 Kg Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Meat 10 Kg from the Cap Gunung brand is the main choice for cooking fans who are looking for superior quality and practicality in large quantities. Packaged in large packages weighing 10 kilograms, this product shows the Cap Gunung brand's commitment to providing high quality ingredients. What makes this Meat Tamarind special is the presence of seed content which is still maintained at 3 to 5%, giving a natural feel to every dish.
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung's decision to maintain the seed content in the 10 Kg Asam Meat creates unlimited opportunities for flavor exploration. Preserved beans provide a new dimension to the culinary experience, not only with a distinctive sour taste but also with a unique touch of texture. With this large packaging, Cap Gunung presents a practical solution for culinary fans who often create in larger quantities, ensuring deliciousness and diversity of flavors in each dish.
                `,
                "kategori": "daging",
                "image": gambar9,
                "ukuran": 10000,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 35.10/dus",
                "isi": "Contents",
                "total_isi": "2 Packs",
            },
            ar: {
                "nama": "لحم حامض 10 كيلو كاب جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 لحم آسام 10 كجم من ماركة كاب جونونج هو الخيار الرئيسي لمحبي الطبخ الذين يبحثون عن الجودة العالية والعملية بكميات كبيرة. يُظهر هذا المنتج المعبأ في عبوات كبيرة تزن 10 كجم التزام العلامة التجارية Cap Gunung بتوفير مكونات عالية الجودة. ما يجعل لحم التمر الهندي هذا مميزًا هو وجود محتوى البذور الذي لا يزال محتفظًا به بنسبة 3 إلى 5%، مما يعطي إحساسًا طبيعيًا لكل طبق.
                \u00a0\u00a0\u00a0\u00a0 قرار Cap Gunung بالحفاظ على محتوى البذور في 10 كجم من لحم Asam يخلق فرصًا غير محدودة لاستكشاف النكهة. توفر الفاصوليا المحفوظة بعدًا جديدًا لتجربة الطهي، ليس فقط من خلال الطعم الحامض المميز ولكن أيضًا مع لمسة فريدة من نوعها. مع هذه العبوة الكبيرة، يقدم Cap Gunung حلاً عمليًا لمحبي الطهي الذين غالبًا ما يقومون بإعداد كميات أكبر، مما يضمن الطعم اللذيذ وتنوع النكهات في كل طبق.
                `,
                "kategori": "daging",
                "image": gambar9,
                "ukuran": 10000,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 128.92/هكذا",
                "isi": "محتويات",
                "total_isi": "2 عبوة",
            },
            zh: {
                "nama": "酸肉 10 公斤 Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 亚参肉 10 公斤是追求卓越品质和大批量实用性的烹饪爱好者的主要选择。 该产品采用重 10 公斤的大包装，体现了 Cap Gunung 品牌对提供高品质原料的承诺。 这款肉罗望子的特别之处在于其种子含量仍保持在 3% 至 5%，给每道菜带来自然的感觉。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 决定保留 10 公斤阿三肉中的种子含量，为风味探索创造了无限机会。 蜜饯豆为烹饪体验带来了新的维度，不仅具有独特的酸味，而且具有独特的质感。 通过这种大包装，Cap Gunung 为经常制作大量菜肴的烹饪爱好者提供了一个实用的解决方案，确保每道菜的美味和口味的多样性。
                `,
                "kategori": "daging",
                "image": gambar9,
                "ukuran": 10000,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 250.14/杜斯",
                "isi": "内容",
                "total_isi": "2 包",
            },
        },
        {
            id: {
                "nama": "Asam Biji 250 Gram Cap Big Sun",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Biji 250 Gram dari Brand Big Sun adalah pilihan terbaik untuk menghadirkan kelezatan asam yang autentik dalam setiap hidangan. Diperoleh dari biji asam berkualitas tinggi, produk ini dikemas dalam kemasan 250 gram yang praktis, menjadikannya pilihan yang mudah digunakan dalam berbagai resep kuliner. Big Sun memberikan perhatian khusus pada proses pemilihan biji asam dan pengolahan, sehingga menghasilkan produk dengan keasaman yang tepat dan cita rasa yang khas. Sempurna untuk memperkaya rasa masakan tradisional atau kreasi kuliner modern Anda.
                \u00a0\u00a0\u00a0\u00a0 Kemasan praktis Asam Biji 250 Gram ini memudahkan Anda untuk menambahkan sentuhan eksotis ke hidangan favorit Anda tanpa repot. Dengan Big Sun, Anda dapat menciptakan hidangan yang menggoda selera dengan mudah, memberikan nuansa asam yang lezat dan menyegarkan. Jadi, pilihlah Asam Biji 250 Gram dari Brand Big Sun untuk merasakan keunikan rasa dan kualitas tinggi dalam setiap sajian kuliner.
                `,
                "kategori": "biji",
                "image": gambar10,
                "ukuran": 250,
                "jenis": "Jenis",
                "asam": "Asam Biji",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 507.000/dus",
                "isi": "Isi",
                "total_isi": "60 Pack",
            },
            en: {
                "nama": "Seed Tamarind 250 Grams Brand Big Sun",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind Seeds 250 Grams from the Big Sun Brand is the best choice to bring authentic delicious sourness to every dish. Obtained from high quality tamarind seeds, this product is packaged in a convenient 250 gram packaging, making it an easy choice to use in various culinary recipes. Big Sun pays special attention to the process of selecting tamarind beans and processing, resulting in a product with the right acidity and distinctive taste. Perfect for enriching the taste of your traditional dishes or modern culinary creations.
                \u00a0\u00a0\u00a0\u00a0 This practical packaging of 250 Gram Seed Tamarind makes it easy for you to add an exotic touch to your favorite dishes without any hassle. With Big Sun, you can create mouth-watering dishes with ease, providing delicious and refreshing sour nuances. So, choose 250 Gram Seed Tamarind from the Big Sun Brand to experience the unique taste and high quality in every culinary dish.
                `,
                "kategori": "biji",
                "image": gambar10,
                "ukuran": 250,
                "jenis": "Type",
                "asam": "Seed Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 32.91/dus",
                "isi": "Contents",
                "total_isi": "60 Packs",
            },
            ar: {
                "nama": "بذور تمر هندي 250 جرام قبعة بيج صن",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 بذور التمر الهندي 250 جرام من العلامة التجارية Big Sun هي الخيار الأفضل لجلب الحموضة اللذيذة الأصيلة إلى كل طبق. تم الحصول على هذا المنتج من بذور التمر الهندي عالية الجودة، ويتم تعبئته في عبوة مريحة بوزن 250 جرام، مما يجعله خيارًا سهلاً للاستخدام في وصفات الطهي المختلفة. تولي شركة Big Sun اهتمامًا خاصًا بعملية اختيار حبوب التمر الهندي ومعالجتها، مما ينتج عنه منتج يتمتع بالحموضة المناسبة والطعم المميز. مثالي لإثراء مذاق أطباقك التقليدية أو إبداعات الطهي الحديثة.
                \u00a0\u00a0\u00a0\u00a0 هذه العبوة العملية المكونة من 250 جرام من بذور التمر الهندي تجعل من السهل عليك إضافة لمسة غريبة إلى أطباقك المفضلة دون أي متاعب. مع Big Sun، يمكنك إعداد أطباق شهية بسهولة، مما يوفر فروقًا دقيقة ولذيذة ومنعشة. لذلك، اختر 250 جرام من بذور التمر الهندي من ماركة Big Sun لتجربة المذاق الفريد والجودة العالية في كل طبق من أطباق الطهي.
                `,
                "kategori": "biji",
                "image": gambar10,
                "ukuran": 250,
                "jenis": "يكتب",
                "asam": "حمض البذور",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 120.86/هكذا",
                "isi": "محتويات",
                "total_isi": "60 عبوة",
            },
            zh: {
                "nama": "罗望子种子 250 克帽大太阳",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Big Sun 品牌罗望子籽 250 克是为每道菜带来正宗美味酸味的最佳选择。该产品取自优质罗望子种子，采用方便的 250 克包装，使其成为各种烹饪食谱中使用的简单选择。 Big Sun特别注重罗望子豆的选择和加工过程，从而使产品具有合适的酸度和独特的口味。非常适合丰富您的传统菜肴或现代烹饪创意的味道。
                \u00a0\u00a0\u00a0\u00a0 这款实用的 250 克罗望子种子包装让您可以轻松地为您喜爱的菜肴添加异国情调，没有任何麻烦。使用 Big Sun，您可以轻松制作令人垂涎欲滴的菜肴，提供美味和清爽的酸味。因此，选择 Big Sun 品牌的 250 克罗望子籽，体验每道菜肴的独特口味和高品质。
                `,
                "kategori": "biji",
                "image": gambar10,
                "ukuran": 250,
                "jenis": "类型",
                "asam": "种子酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 234.50/杜斯",
                "isi": "内容",
                "total_isi": "60 包",
            },
        },
        // {
        //     id: {
        //         "nama": "Asam Biji 30 Gram Cap Gunung",
        //         // "deskripsi": `
        //         // \u00a0\u00a0\u00a0 Asam Biji 400 Gram dari Brand Cap Gunung merupakan pilihan sempurna untuk memberikan sentuhan eksotis pada hidangan Anda. Diproduksi dengan teliti oleh Brand Cap Gunung, produk ini menawarkan biji asam berkualitas tinggi yang diolah dengan cermat untuk menciptakan rasa yang autentik. Dikemas dalam kemasan 400 gram, Asam Biji ini memberikan kenyamanan dan kepraktisan dalam penggunaan sehari-hari.
        //         // \u00a0\u00a0\u00a0\u00a0 Brand Cap Gunung dikenal akan komitmen mereka terhadap kualitas, dan Asam Biji 400 Gram tidak terkecuali. Keasaman yang seimbang dan cita rasa yang khas membuatnya cocok untuk berbagai resep masakan, baik yang bersifat tradisional maupun modern. Segera tambahkan sentuhan spesial ke masakan Anda dengan Asam Biji 400 Gram dari Brand Cap Gunung, dan nikmati pengalaman kuliner yang istimewa dengan setiap suapannya.
        //         // `,
        //         "kategori": "biji",
        //         "image": gambar37,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "Jenis",
        //         "asam": "Asam Biji",
        //         "kemasan": "Kemasan",
        //         "tradisional": "Tradisional",
        //         "harga": "Harga",
        //         "total": "IDR 12.000/dus",
        //         "isi": "Isi",
        //         "total_isi": "40 Pack",
        //         "pack": "1 Pack",
        //         "total_pack": "12 renceng"
        //     },
        //     en: {
        //         "nama": "Tamarind Seeds 30 Grams Brain Mountain",
        //         // "deskripsi": `
        //         // \u00a0\u00a0\u00a0 Asam Seeds 400 Grams from the Cap Gunung Brand is the perfect choice to give an exotic touch to your dishes. Carefully produced by the Cap Gunung Brand, this product offers high quality tamarind seeds that are carefully processed to create an authentic taste. Packaged in 400 gram packaging, this Asam Seed provides comfort and practicality in daily use.
        //         // \u00a0\u00a0\u00a0\u00a0 The Cap Gunung brand is known for their commitment to quality, and Asam Biji 400 Gram is no exception. Its balanced acidity and distinctive taste make it suitable for various recipes, both traditional and modern. Immediately add a special touch to your cooking with Asam Biji 400 Gram from the Cap Gunung Brand, and enjoy a special culinary experience with every bite.
        //         // `,
        //         "kategori": "biji",
        //         "image": gambar37,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "Type",
        //         "asam": "Seed Acid",
        //         "kemasan": "Packaging",
        //         "tradisional": "Traditional",
        //         "harga": "Price",
        //         "total": "USD 0.77/dus",
        //         "isi": "Contents",
        //         "total_isi": "40 Packs",
        //         "pack": "1 Pack",
        //         "total_pack": "12 plans"
        //     },
        //     ar: {
        //         "nama": "بذور التمر الهندي 30 جرام برين ماونتن",
        //         // "deskripsi": `
        //         // \u00a0\u00a0\u00a0\u00a0 بذور التمر الهندي 400 جرام من ماركة Cap Gunung هي الخيار الأمثل لإضفاء لمسة غريبة على أطباقك. تم إنتاج هذا المنتج بعناية من قبل العلامة التجارية Cap Gunung، ويقدم بذور التمر الهندي عالية الجودة والتي تتم معالجتها بعناية لخلق طعم أصيل. معبأة في عبوة 400 جرام، توفر Asam Seed الراحة والعملية في الاستخدام اليومي.
        //         // \u00a0\u00a0\u00a0\u00a0 العلامة التجارية Cap Gunung معروفة بالتزامها بالجودة، وAsam Biji 400 Gram ليس استثناءً. حموضته المتوازنة وطعمه المميز يجعله مناسباً لمختلف الوصفات التقليدية والحديثة. أضف على الفور لمسة خاصة إلى طبخك مع أسام بيجي 400 جرام من ماركة كاب جونونج، واستمتع بتجربة طهي خاصة مع كل قضمة.
        //         // `,
        //         "kategori": "biji",
        //         "image": gambar37,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "يكتب",
        //         "asam": "حمض البذور",
        //         "kemasan": "التعبئة والتغليف",
        //         "tradisional": "تقليدي",
        //         "harga": "سعر",
        //         "total": "AED 0.000236/هكذا",
        //         "isi": "محتويات",
        //         "total_isi": "40 عبوة",
        //         "pack": "1 حزمة",
        //         "total_pack": "12 خطط"
        //     },
        //     zh: {
        //         "nama": "罗望子种子 30 克脑山",
        //         // "deskripsi": `
        //         // \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的阿萨姆籽 400 克是为您的菜肴增添异域风情的完美选择。 该产品由 Cap Gunung 品牌精心生产，提供高品质的罗望子种子，经过精心加工，创造出正宗的味道。 这款阿三籽采用 400 克包装，在日常使用中提供舒适性和实用性。
        //         // \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌以其对质量的承诺而闻名，Asam Biji 400 克也不例外。 其平衡的酸度和独特的口味使其适合各种传统和现代食谱。 使用 Cap Gunung 品牌的 Asam Biji 400 克立即为您的烹饪增添特殊的风味，每一口都享受特殊的烹饪体验。
        //         // `,
        //         "kategori": "biji",
        //         "image": gambar37,
        //         "ukuran": 30,
        //         "brand": "gunung",
        //         "jenis": "类型",
        //         "asam": "种子酸",
        //         "kemasan": "包装",
        //         "tradisional": "传统的",
        //         "harga": "价格",
        //         "total": "CNY 0.00045/هكذا",
        //         "isi": "内容",
        //         "total_isi": "40 包",
        //         "pack": "1 包",
        //         "total_pack": "12个计划"
        //     },
        // },
        {
            id: {
                "nama": "Asam Biji 400 Gram Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0 Asam Biji 400 Gram dari Brand Cap Gunung merupakan pilihan sempurna untuk memberikan sentuhan eksotis pada hidangan Anda. Diproduksi dengan teliti oleh Brand Cap Gunung, produk ini menawarkan biji asam berkualitas tinggi yang diolah dengan cermat untuk menciptakan rasa yang autentik. Dikemas dalam kemasan 400 gram, Asam Biji ini memberikan kenyamanan dan kepraktisan dalam penggunaan sehari-hari.
                \u00a0\u00a0\u00a0\u00a0 Brand Cap Gunung dikenal akan komitmen mereka terhadap kualitas, dan Asam Biji 400 Gram tidak terkecuali. Keasaman yang seimbang dan cita rasa yang khas membuatnya cocok untuk berbagai resep masakan, baik yang bersifat tradisional maupun modern. Segera tambahkan sentuhan spesial ke masakan Anda dengan Asam Biji 400 Gram dari Brand Cap Gunung, dan nikmati pengalaman kuliner yang istimewa dengan setiap suapannya.
                `,
                "kategori": "biji",
                "image": gambar11,
                "ukuran": 400,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Biji",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 405.500/dus",
                "isi": "Isi",
                "total_isi": "40 Pack",
                "pack": "1 Pack",
                "total_pack": "12 renceng"
            },
            en: {
                "nama": "Tamarind Seeds 400 Grams Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0 Asam Seeds 400 Grams from the Cap Gunung Brand is the perfect choice to give an exotic touch to your dishes. Carefully produced by the Cap Gunung Brand, this product offers high quality tamarind seeds that are carefully processed to create an authentic taste. Packaged in 400 gram packaging, this Asam Seed provides comfort and practicality in daily use.
                \u00a0\u00a0\u00a0\u00a0 The Cap Gunung brand is known for their commitment to quality, and Asam Biji 400 Gram is no exception. Its balanced acidity and distinctive taste make it suitable for various recipes, both traditional and modern. Immediately add a special touch to your cooking with Asam Biji 400 Gram from the Cap Gunung Brand, and enjoy a special culinary experience with every bite.
                `,
                "kategori": "biji",
                "image": gambar11,
                "ukuran": 400,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Seed Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 26.32/dus",
                "isi": "Contents",
                "total_isi": "40 Packs",
                "pack": "1 Pack",
                "total_pack": "12 plans"
            },
            ar: {
                "nama": "بذور تمر هندي 400 جرام كاب جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 بذور التمر الهندي 400 جرام من ماركة Cap Gunung هي الخيار الأمثل لإضفاء لمسة غريبة على أطباقك. تم إنتاج هذا المنتج بعناية من قبل العلامة التجارية Cap Gunung، ويقدم بذور التمر الهندي عالية الجودة والتي تتم معالجتها بعناية لخلق طعم أصيل. معبأة في عبوة 400 جرام، توفر Asam Seed الراحة والعملية في الاستخدام اليومي.
                \u00a0\u00a0\u00a0\u00a0 العلامة التجارية Cap Gunung معروفة بالتزامها بالجودة، وAsam Biji 400 Gram ليس استثناءً. حموضته المتوازنة وطعمه المميز يجعله مناسباً لمختلف الوصفات التقليدية والحديثة. أضف على الفور لمسة خاصة إلى طبخك مع أسام بيجي 400 جرام من ماركة كاب جونونج، واستمتع بتجربة طهي خاصة مع كل قضمة.
                `,
                "kategori": "biji",
                "image": gambar11,
                "ukuran": 400,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض البذور",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 96.66/هكذا",
                "isi": "محتويات",
                "total_isi": "40 عبوة",
                "pack": "1 حزمة",
                "total_pack": "12 خطط"
            },
            zh: {
                "nama": "罗望子种子 400 克 Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的阿萨姆籽 400 克是为您的菜肴增添异域风情的完美选择。 该产品由 Cap Gunung 品牌精心生产，提供高品质的罗望子种子，经过精心加工，创造出正宗的味道。 这款阿三籽采用 400 克包装，在日常使用中提供舒适性和实用性。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌以其对质量的承诺而闻名，Asam Biji 400 克也不例外。 其平衡的酸度和独特的口味使其适合各种传统和现代食谱。 使用 Cap Gunung 品牌的 Asam Biji 400 克立即为您的烹饪增添特殊的风味，每一口都享受特殊的烹饪体验。
                `,
                "kategori": "biji",
                "image": gambar11,
                "ukuran": 400,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "种子酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 96.66/هكذا",
                "isi": "内容",
                "total_isi": "40 包",
                "pack": "1 包",
                "total_pack": "12个计划"
            },
        },
        {
            id: {
                "nama": "Asam Biji Hitam 500 Gram Cap Gunung",
                "deskripsi": `
                  \u00a0\u00a0\u00a0\u00a0 Asam Biji Hitam 500 gram dari brand Cap Gunung adalah pilihan sempurna untuk memperkaya dapur Anda dengan sentuhan khas rempah Indonesia. Diproduksi dengan teliti oleh Cap Gunung, brand yang telah teruji kualitasnya, produk ini menawarkan kelezatan asam biji hitam dalam kemasan praktis 500 gram. Dengan rasa yang autentik dan konsistensi kualitas, asam biji hitam Cap Gunung memberikan dimensi baru pada hidangan Anda, memastikan pengalaman masak yang tak terlupakan.
                  \u00a0\u00a0\u00a0\u00a0 Selain itu, Asam Biji Hitam Cap Gunung tidak hanya menghadirkan kelezatan, tetapi juga menawarkan kemudahan dalam penggunaan sehari-hari. Kemasan 500 gram memungkinkan Anda untuk menambahkan sentuhan khusus ini ke berbagai hidangan, dari masakan sehari-hari hingga kreasi kuliner yang lebih istimewa. Percayakan brand Cap Gunung untuk memenuhi kebutuhan rempah-rempah berkualitas tinggi dan nikmati kelezatan tiada tara dalam setiap sajian Anda.
                `,
                "kategori": "biji",
                "image": gambar12,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Biji",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 405.500/dus",
                "isi": "Isi",
                "total_isi": "40 Pack",
            },
            en: {
                "nama": "Tamarind Seeds 500 Grams Brand Mountain",
                "deskripsi": `
                   \u00a0\u00a0\u00a0\u00a0 Asam Seed Hitam 500 grams from the Cap Gunung brand is the perfect choice to enrich your kitchen with a touch of typical Indonesian spices. Produced carefully by Cap Gunung, a brand whose quality has been tested, this product offers the delicious taste of black seed acid in a practical 500 gram packaging. With authentic taste and consistent quality, Cap Gunung black seed tamarind adds a new dimension to your dishes, ensuring an unforgettable cooking experience.
                   \u00a0\u00a0\u00a0\u00a0 Apart from that, Cap Gunung Black Seed Acid not only provides deliciousness, but also offers convenience in daily use. The 500 gram packaging allows you to add this special touch to a variety of dishes, from everyday dishes to more special culinary creations. Trust the Cap Gunung brand to fulfill your needs for high-quality spices and enjoy incomparable deliciousness in every dish you serve.
                 `,
                "kategori": "biji",
                "image": gambar12,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Seed Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 26.32/dus",
                "isi": "Fill",
                "total_isi": "40 Pack",
            },
            ar: {
                "nama": "بذور التمر الهندي 500 جرام كاب جونونج",
                "deskripsi": `
                   \u00a0\u00a0\u00a0\u00a0 Asam Seed Hitam 500 جرام من ماركة Cap Gunung هو الخيار الأمثل لإثراء مطبخك بلمسة من التوابل الإندونيسية النموذجية. تم إنتاج هذا المنتج بعناية من قبل شركة Cap Gunung، وهي علامة تجارية تم اختبار جودتها، ويقدم هذا المنتج المذاق اللذيذ لحمض الحبة السوداء في عبوة عملية سعة 500 جرام. مع المذاق الأصيل والجودة المتسقة، يضيف التمر الهندي بالحبة السوداء من Cap Gunung بعدًا جديدًا إلى أطباقك، مما يضمن تجربة طهي لا تُنسى.
                   \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن ذلك، فإن حمض الحبة السوداء Cap Gunung لا يوفر الطعم اللذيذ فحسب، بل يوفر أيضًا الراحة في الاستخدام اليومي. تتيح لك العبوة التي يبلغ وزنها 500 جرام إضافة هذه اللمسة الخاصة إلى مجموعة متنوعة من الأطباق، بدءًا من الأطباق اليومية وحتى إبداعات الطهي الخاصة. ثق في العلامة التجارية Cap Gunung لتلبية احتياجاتك من التوابل عالية الجودة والاستمتاع بمذاق لا يضاهى في كل طبق تقدمه.
                 `,
                "kategori": "biji",
                "image": gambar12,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض البذور",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 96.66/هكذا",
                "isi": "يملأ",
                "total_isi": "40 عبوة",
            },
            zh: {
                "nama": "罗望子种子 500 克 Cap Gunung",
                "deskripsi": `
                   \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 Asam Seed Hitam 500 克是用典型的印度尼西亚香料丰富您的厨房的完美选择。 该产品由质量经过检验的 Cap Gunung 品牌精心生产，在实用的 500 克包装中提供黑籽酸的美味。 Cap Gunung 黑籽罗望子具有正宗的口味和稳定的品质，为您的菜肴增添了新的维度，确保为您带来难忘的烹饪体验。
                   \u00a0\u00a0\u00a0\u00a0 除此之外，Cap Gunung 黑籽酸不仅提供美味，还为日常使用提供便利。 500 克的包装让您可以将这种特殊的感觉添加到各种菜肴中，从日常菜肴到更特别的烹饪创意。 相信 Cap Gunung 品牌能够满足您对高品质香料的需求，并在您提供的每道菜中享受无与伦比的美味。
                 `,
                "kategori": "biji",
                "image": gambar12,
                "ukuran": 500,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "种子酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 96.66/هكذا",
                "isi": "内容",
                "total_isi": "40 包",
            },
        },
        //  {
        //     id: {
        //         "nama": "Asam Biji K23 500 Gram Cap Gunung",
        //         "deskripsi": `
        //           \u00a0\u00a0\u00a0\u00a0 Asam Biji K23 500 gram dari brand Cap Gunung menghadirkan keajaiban rempah Indonesia langsung ke dalam dapur Anda. Diproduksi dengan teliti oleh Cap Gunung, brand yang telah menjadi simbol kualitas, produk ini merupakan pilihan sempurna untuk menambahkan sentuhan eksotis pada hidangan Anda. Dengan kemasan praktis berat 500 gram, Asam Biji K23 memberikan kemudahan dalam penggunaan sehari-hari tanpa mengorbankan keaslian dan kelezatan rasa.
        //           \u00a0\u00a0\u00a0\u00a0 Keunggulan produk ini tidak hanya terletak pada rasa autentiknya, tetapi juga pada keberagaman penggunaan. Asam Biji K23 Cap Gunung yang masih mengandung 3 hingga 5 persen biji menjadikannya pilihan yang unik. Dengan setiap penggunaan, Anda dapat menghadirkan cita rasa istimewa ke dalam masakan sehari-hari atau kreasi kuliner yang lebih eksklusif. Percayakan brand Cap Gunung untuk pengalaman memasak yang luar biasa dan rasakan kelezatan dari setiap sajian dengan Asam Biji K23 500 gram.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar12,
        //         "ukuran": 500,
        //         "brand": "gunung",
        //         "jenis": "Jenis",
        //         "asam": "Asam Biji",
        //         "kemasan": "Kemasan",
        //         "tradisional": "Tradisional",
        //         "harga": "Harga",
        //         "total": "IDR 371.500/dus",
        //         "isi": "Isi",
        //         "total_isi": "40 Pack",
        //     },
        //     en: {
        //         "nama": "Tamarind Seeds 500 Grams Brand Mountain",
        //         "deskripsi": `
        //            \u00a0\u00a0\u00a0\u00a0 Asam Biji K23 500 grams from the Cap Gunung brand brings the magic of Indonesian spices straight into your kitchen. Meticulously produced by Cap Gunung, a brand that has become a symbol of quality, this product is the perfect choice to add an exotic touch to your dishes. With a practical packaging weighing 500 grams, Asam Biji K23 provides convenience in daily use without sacrificing authenticity and delicious taste.
        //            \u00a0\u00a0\u00a0\u00a0 The advantage of this product lies not only in its authentic taste, but also in the diversity of uses. Asam Seed K23 Cap Gunung still contains 3 to 5 percent seeds, making it a unique choice. With each use, you can bring a special taste to everyday dishes or more exclusive culinary creations. Trust the Cap Gunung brand for an extraordinary cooking experience and taste the deliciousness of every dish with Asam Biji K23 500 grams.
        //          `,
        //         "kategori": "biji",
        //         "image": gambar12,
        //         "ukuran": 500,
        //         "brand": "gunung",
        //         "jenis": "Type",
        //         "asam": "Seed Acid",
        //         "kemasan": "Packaging",
        //         "tradisional": "Traditional",
        //         "harga": "Price",
        //         "total": "USD 24.11/dus",
        //         "isi": "Fill",
        //         "total_isi": "40 Pack",
        //     },
        //     ar: {
        //         "nama": "بذور التمر الهندي 500 جرام كاب جونونج",
        //         "deskripsi": `
        //            \u00a0\u00a0\u00a0\u00a0 Asam Biji K23 500 جرام من ماركة Cap Gunung يجلب سحر التوابل الإندونيسية مباشرة إلى مطبخك. تم إنتاج هذا المنتج بدقة من قبل شركة Cap Gunung، وهي العلامة التجارية التي أصبحت رمزًا للجودة، وهو الخيار الأمثل لإضافة لمسة غريبة إلى أطباقك. مع عبوة عملية تزن 500 جرام، يوفر Asam Biji K23 الراحة في الاستخدام اليومي دون التضحية بالأصالة والطعم اللذيذ.
        //            \u00a0\u00a0\u00a0\u00a0 ميزة هذا المنتج لا تكمن فقط في مذاقه الأصيل، ولكن أيضًا في تنوع الاستخدامات. لا يزال Asam Seed K23 Cap Gunung يحتوي على 3 إلى 5 بالمائة من البذور، مما يجعله خيارًا فريدًا. مع كل استخدام، يمكنك إضفاء مذاق خاص على الأطباق اليومية أو المزيد من إبداعات الطهي الحصرية. ثق بعلامة Cap Gunung التجارية لتجربة طهي غير عادية وتذوق لذيذ كل طبق مع Asam Biji K23 500 جرام.
        //          `,
        //         "kategori": "biji",
        //         "image": gambar12,
        //         "ukuran": 500,
        //         "brand": "gunung",
        //         "jenis": "يكتب",
        //         "asam": "حمض البذور",
        //         "kemasan": "التعبئة والتغليف",
        //         "tradisional": "تقليدي",
        //         "harga": "سعر",
        //         "total": "AED 88.56/هكذا",
        //         "isi": "يملأ",
        //         "total_isi": "40 عبوة",
        //     },
        //     zh: {
        //         "nama": "罗望子种子 500 克 Cap Gunung",
        //         "deskripsi": `
        //            \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 Asam Biji K23 500 克将印度尼西亚香料的魔力直接带入您的厨房。 该产品由已成为品质象征的品牌 Cap Gunung 精心制作，是为您的菜肴增添异国情调的完美选择。 Asam Biji K23采用重500克的实用包装，为日常使用提供便利，同时又不牺牲真实性和美味。
        //            \u00a0\u00a0\u00a0\u00a0 该产品的优点不仅在于味道正宗，还在于用途的多样性。 Asam Biji K23 Cap Gunung 仍含有 3% 至 5% 的种子，使其成为独特的选择。 每次使用，您都可以为日常菜肴或更独特的烹饪创意带来特殊的味道。 相信 Cap Gunung 品牌将为您带来非凡的烹饪体验，并使用 Asam Biji K23 500 克品尝每道菜的美味。
        //          `,
        //         "kategori": "biji",
        //         "image": gambar12,
        //         "ukuran": 500,
        //         "brand": "gunung",
        //         "jenis": "类型",
        //         "asam": "种子酸",
        //         "kemasan": "包装",
        //         "tradisional": "传统的",
        //         "harga": "价格",
        //         "total": "CNY 171.83/杜斯",
        //         "isi": "内容",
        //         "total_isi": "40 包",
        //     },
        // },
        // {
        //     id: {
        //         "nama": "Asam Biji 800 Gram Cap Bunga Merah",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 800 Gram dari brand Cap Bunga Merah menghadirkan kelezatan asam yang begitu memikat dalam setiap gigitannya. Brand Cap Bunga Merah telah dengan cermat memilih biji asam berkualitas tinggi, memberikan produk yang melampaui harapan dalam menciptakan pengalaman kuliner yang unik. Dengan kemasan berisi 800 gram, Asam Biji ini tidak hanya menjadi pilihan praktis untuk dapur sehari-hari, tetapi juga memungkinkan eksplorasi kreatifitas dalam memasak, memperkaya rasa hidangan favorit Anda.
        //         \u00a0\u00a0\u00a0\u00a0 Brand Cap Bunga Merah menonjolkan komitmen mereka terhadap standar kualitas tinggi dengan Asam Biji 800 Gram ini. Keasaman yang seimbang dan karakter rasa yang khas menjadikan produk ini sebagai bahan andalan untuk berbagai jenis masakan. Menyajikan rasa yang autentik dan memukau, Asam Biji 800 Gram dari brand Cap Bunga Merah akan mengangkat tingkat kelezatan hidangan Anda, menciptakan momen kuliner yang tak terlupakan.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar13,
        //         "ukuran": 800
        //     },
        //     en: {
        //         "nama": "Seed Tamarind 800 Grams Red Flower Stamp",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Seeds 800 Grams from the Cap Bunga Merah brand delivers delicious sourness that is so alluring in every bite. The Cap Bunga Merah brand has carefully selected high-quality tamarind beans, delivering a product that exceeds expectations in creating a unique culinary experience. With a packaging containing 800 grams, this Asam Seed is not only a practical choice for the everyday kitchen, but also allows for creative exploration in cooking, enriching the taste of your favorite dishes.
        //         \u00a0\u00a0\u00a0\u00a0 Brand Cap Bunga Merah highlights their commitment to high quality standards with this 800 Gram Seed Tamarind. The balanced acidity and distinctive taste character make this product a mainstay ingredient for various types of dishes. Providing an authentic and stunning taste, Asam Biji 800 Gram from the Cap Bunga Merah brand will raise the level of deliciousness of your dishes, creating unforgettable culinary moments.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar13,
        //         "ukuran": 800
        //     },
        //     ar: {
        //         "nama": "بذور تمر هندي 800 جرام ختم زهرة حمراء",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Seeds 800 جرام من العلامة التجارية Cap Bunga Merah تقدم نكهة لذيذة وجذابة للغاية في كل قضمة. قامت العلامة التجارية Cap Bunga Merah باختيار حبوب التمر الهندي عالية الجودة بعناية، لتقدم منتجًا يتجاوز التوقعات في خلق تجربة طهي فريدة من نوعها. مع عبوة تحتوي على 800 جرام، لا تعد بذور Asam هذه خيارًا عمليًا للمطبخ اليومي فحسب، ولكنها تسمح أيضًا بالاستكشاف الإبداعي في الطهي، مما يثري مذاق أطباقك المفضلة.
        //         \u00a0\u00a0\u00a0\u00a0 العلامة التجارية Cap Bunga Merah تسلط الضوء على التزامهم بمعايير الجودة العالية مع بذور التمر الهندي سعة 800 جرام. الحموضة المتوازنة والطعم المميز يجعل هذا المنتج مكونًا أساسيًا لأنواع مختلفة من الأطباق. يقدم Asam Biji 800 Gram من العلامة التجارية Cap Bunga Merah مذاقًا أصيلًا ومذهلًا، وسيرفع مستوى لذة أطباقك، مما يخلق لحظات طهي لا تُنسى.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar13,
        //         "ukuran": 800
        //     },
        //     zh: {
        //         "nama": "种子罗望子 800 克红花邮票",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah 品牌的 800 克阿萨姆籽提供美味的酸味，每一口都令人着迷。 Cap Bunga Merah 品牌精心挑选高品质罗望子豆，提供超出预期的产品，创造独特的烹饪体验。 这款阿三籽的包装重量为 800 克，不仅是日常厨房的实用选择，还可以让您在烹饪中进行创意探索，丰富您喜爱的菜肴的味道。
        //         \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah 品牌通过这款 800 克罗望子种子强调了他们对高质量标准的承诺。 平衡的酸度和独特的口味特征使该产品成为各种菜肴的主要成分。 Cap Bunga Merah 品牌的 Asam Biji 800 克提供正宗而令人惊叹的口味，将提高您菜肴的美味水平，创造难忘的美食时刻。
        //         `,
        //         "kategori": "biji",
        //         "image": gambar13,
        //         "ukuran": 800
        //     },
        // },
        {
            id: {
                "nama": "Asam Biji Hitam 1 Kg Cap Gunung",
                "deskripsi": `
                  \u00a0\u00a0\u00a0\u00a0 Asam Biji Hitam 1 Kg dari brand Cap Gunung merupakan pilihan utama bagi para penggemar masakan Indonesia yang mendambakan kelezatan dan keaslian rasa. Dibuat dengan teliti oleh brand yang telah dikenal sebagai ahli dalam memproduksi rempah-rempah berkualitas tinggi, produk ini menawarkan pengalaman kuliner yang tak tertandingi. Asam Biji Hitam khas Cap Gunung hadir dalam kemasan 1 Kg, memberikan fleksibilitas dalam penggunaan sehari-hari dan memastikan bahwa kebutuhan dapur Anda akan terpenuhi dengan baik. Dipilih dengan cermat, biji hitam ini menambahkan sentuhan unik pada berbagai hidangan, mulai dari masakan Padang hingga seafood, dengan memberikan rasa asam yang seimbang dan menggoda selera.
                  \u00a0\u00a0\u00a0\u00a0 Keistimewaan lain dari Asam Biji Hitam 1 Kg ini terletak pada kemasannya yang praktis dan fungsional. Cap Gunung memastikan bahwa setiap kemasan dirancang untuk memudahkan akses dan penyimpanan, sementara tetap menjaga kelembapan dan keaslian produk. Dengan mengandalkan Asam Biji Hitam dari brand ini, Anda dapat yakin bahwa masakan Anda akan selalu memukau lidah keluarga atau tamu Anda. Jadi, hadirkan cita rasa autentik Indonesia dengan menggunakan Asam Biji Hitam 1 Kg dari brand Cap Gunung di dapur Anda, dan nikmati pengalaman kuliner yang tiada duanya.
                `,
                "kategori": "biji",
                "image": gambar14,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "Jenis",
                "asam": "Asam Biji",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 371.500/dus",
                "isi": "Isi",
                "total_isi": "20 Pack",
            },
            en:  {
                "nama": "Black Seed Tamarind 1 Kg Brand Mountain",
                "deskripsi": `
                   \u00a0\u00a0\u00a0\u00a0 Asam Seed Hitam 1 Kg from the Cap Gunung brand is the main choice for fans of Indonesian cuisine who crave deliciousness and authentic taste. Carefully crafted by a brand that is known as an expert in producing high-quality spices, this product offers an unrivaled culinary experience. Cap Gunung's signature Black Seed Acid comes in 1 Kg packaging, providing flexibility in daily use and ensuring that your kitchen needs will be met properly. Carefully selected, these black seeds add a unique touch to a variety of dishes, from Padang cuisine to seafood, by providing a balanced and mouth-watering sour taste.
                   \u00a0\u00a0\u00a0\u00a0 Another specialty of 1 Kg Black Seed Acid lies in its practical and functional packaging. Cap Gunung ensures that each package is designed for easy access and storage, while maintaining product moisture and authenticity. By relying on Black Seed Acid from this brand, you can be sure that your cooking will always amaze the taste buds of your family or guests. So, bring authentic Indonesian flavors by using 1 Kg Black Seed Asam from the Cap Gunung brand in your kitchen, and enjoy a culinary experience like no other.
                 `,
                "kategori": "biji",
                "image": gambar14,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "Type",
                "asam": "Seed Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 24.11/dus",
                "isi": "Fill",
                "total_isi": "20 Pack",
            },
            ar: {
                "nama": "تمر هندي حبة سوداء 1 كجم كاب جونونج",
                "deskripsi": `
                   \u00a0\u00a0\u00a0\u00a0 Asam Seed Hitam 1 Kg من ماركة Cap Gunung هو الخيار الرئيسي لمحبي المطبخ الإندونيسي الذين يتوقون إلى اللذة والذوق الأصيل. تم تصميم هذا المنتج بعناية من قبل علامة تجارية معروفة بأنها خبيرة في إنتاج التوابل عالية الجودة، ويقدم تجربة طهي لا مثيل لها. يأتي حمض الحبة السوداء المميز من Cap Gunung في عبوة بحجم 1 كجم، مما يوفر المرونة في الاستخدام اليومي ويضمن تلبية احتياجات مطبخك بشكل صحيح. تضيف هذه البذور السوداء المختارة بعناية لمسة فريدة لمجموعة متنوعة من الأطباق، بدءًا من مطبخ بادانج وحتى المأكولات البحرية، من خلال توفير طعم حامض متوازن وشهي.
                   \u00a0\u00a0\u00a0\u00a0 هناك تخصص آخر من 1 كجم من حمض الحبة السوداء يكمن في عبوته العملية والوظيفية. يضمن Cap Gunung أن كل عبوة مصممة لسهولة الوصول إليها وتخزينها، مع الحفاظ على رطوبة المنتج وأصالته. من خلال الاعتماد على حمض الحبة السوداء من هذه العلامة التجارية، يمكنك التأكد من أن الطبخ الخاص بك سوف يذهل دائمًا ذوق عائلتك أو ضيوفك. لذلك، أحضر النكهات الإندونيسية الأصيلة باستخدام 1 كجم من الحبة السوداء Asam من ماركة Cap Gunung في مطبخك، واستمتع بتجربة طهي لا مثيل لها.
                 `,
                "kategori": "biji",
                "image": gambar14,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "يكتب",
                "asam": "حمض البذور",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 88.56/هكذا",
                "isi": "يملأ",
                "total_isi": "20 عبوة",
            },
            zh: {
                "nama": "黑籽罗望子 1 公斤 Cap Gunung",
                "deskripsi": `
                   \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 Asam Seed Hitam 1 Kg 是追求美味和正宗味道的印尼美食爱好者的主要选择。 该产品由以生产优质香料而闻名的专家品牌精心制作，提供无与伦比的烹饪体验。 Cap Gunung 的标志性黑籽酸采用 1 公斤包装，为日常使用提供灵活性，并确保适当满足您的厨房需求。 这些黑色种子经过精心挑选，为从巴东美食到海鲜的各种菜肴增添了独特的风味，提供均衡且令人垂涎的酸味。
                   \u00a0\u00a0\u00a0\u00a0 1公斤黑籽酸的另一个特色在于其实用且功能性的包装。 Cap Gunung 确保每个包装的设计都易于取用和储存，同时保持产品的水分和真实性。 通过使用该品牌的黑籽酸，您可以确信您的烹饪将始终让您的家人或客人的味蕾惊叹不已。 因此，在您的厨房中使用 1 公斤 Cap Gunung 品牌的黑籽亚参带来正宗的印尼风味，享受无与伦比的美食体验。
                 `,
                "kategori": "biji",
                "image": gambar14,
                "ukuran": 1000,
                "brand": "gunung",
                "jenis": "类型",
                "asam": "种子酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 171.83/杜斯",
                "isi": "内容",
                "total_isi": "20 包",
            },
        },
        // {
        //     id: {
        //         "nama": "Asam Biji K23 1 Kg Cap Gunung",
        //         "deskripsi": `
        //           \u00a0\u00a0\u00a0\u00a0 Asam Biji K23 1 Kg dari brand Cap Gunung merupakan pilihan yang sempurna bagi pecinta masakan Indonesia yang autentik. Diproduksi dengan cermat oleh brand terpercaya, produk ini mengusung kualitas terbaik untuk menjamin kelezatan hidangan Anda. Asam Biji K23 hadir dalam kemasan 1 Kg, memberikan kemudahan dan kepraktisan dalam penggunaan sehari-hari. Keaslian dan cita rasa asam yang khas membuatnya menjadi bumbu yang tidak dapat diabaikan dalam berbagai masakan tradisional, mulai dari rendang hingga gulai. Cap Gunung sebagai produsen terkemuka dalam industri rempah-rempah menjamin bahwa setiap butir asam biji K23 ini terpilih dengan teliti untuk memberikan sentuhan istimewa pada hidangan Anda.
        //           \u00a0\u00a0\u00a0\u00a0 Keunggulan lain dari Asam Biji K23 1 Kg dari brand Cap Gunung terletak pada kemasannya yang praktis dan higienis. Dengan desain yang mudah dibuka dan ditutup kembali, produk ini menjaga kesegaran dan kualitasnya hingga digunakan sepenuhnya. Kemasan yang kuat juga melindungi asam biji dari paparan udara dan cahaya, menjaga kelestarian aroma dan rasa yang autentik. Dengan menggunakan Asam Biji K23 dari brand Cap Gunung, Anda dapat menghadirkan cita rasa terbaik dalam setiap sajian masakan tradisional Indonesia, menjadikannya pilihan yang tak tergantikan di dapur Anda.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar14,
        //         "ukuran": 1000,
        //         "brand": "gunung",
        //         "jenis": "Jenis",
        //         "asam": "Asam Biji",
        //         "kemasan": "Kemasan",
        //         "tradisional": "Tradisional",
        //         "harga": "Harga",
        //         "total": "IDR 338.000/dus",
        //         "isi": "Isi",
        //         "total_isi": "20 Pack",
        //     },
        //     en:  {
        //         "nama": "K23 Seed Acid 1 Kg Brand Mountain",
        //         "deskripsi": `
        //            \u00a0\u00a0\u00a0\u00a0 Asam Biji K23 1 Kg from the Cap Gunung brand is the perfect choice for lovers of authentic Indonesian cuisine. Carefully produced by a trusted brand, this product offers the best quality to ensure the deliciousness of your dishes. K23 Seed Acid comes in 1 Kg packaging, providing convenience and practicality in daily use. Its authenticity and distinctive sour taste make it a spice that cannot be ignored in various traditional dishes, from rendang to curry. Cap Gunung as a leading manufacturer in the spice industry guarantees that every grain of K23 tamarind seeds is carefully selected to give a special touch to your dishes.
        //            \u00a0\u00a0\u00a0\u00a0 Another advantage of K23 Seed Acid 1 Kg from the Cap Gunung brand lies in its practical and hygienic packaging. With a design that is easy to open and close again, this product maintains its freshness and quality until it is fully used. Sturdy packaging also protects the beans from exposure to air and light, preserving the authentic aroma and taste. By using K23 Seed Asam from the Cap Gunung brand, you can bring the best taste to every traditional Indonesian dish, making it an irreplaceable choice in your kitchen.
        //          `,
        //         "kategori": "biji",
        //         "image": gambar14,
        //         "ukuran": 1000,
        //         "brand": "gunung",
        //         "jenis": "Type",
        //         "asam": "Seed Acid",
        //         "kemasan": "Packaging",
        //         "tradisional": "Traditional",
        //         "harga": "Price",
        //         "total": "USD 21.94/dus",
        //         "isi": "Fill",
        //         "total_isi": "20 Pack",
        //     },
        //     ar: {
        //         "nama": "حمض البذور K23 1 كجم كاب جونونج",
        //         "deskripsi": `
        //            \u00a0\u00a0\u00a0\u00a0 Asam Biji K23 1 Kg من ماركة Cap Gunung هو الخيار الأمثل لمحبي المطبخ الإندونيسي الأصيل. تم إنتاج هذا المنتج بعناية من قبل علامة تجارية موثوقة، وهو يقدم أفضل جودة لضمان مذاق أطباقك اللذيذة. يأتي حمض بذور K23 في عبوة بوزن 1 كجم، مما يوفر الراحة والعملية في الاستخدام اليومي. أصالته وطعمه اللاذع المميز يجعله من التوابل التي لا يمكن تجاهلها في مختلف الأطباق التقليدية، من الرندانج إلى الكاري. تضمن شركة Cap Gunung كشركة مصنعة رائدة في صناعة التوابل أن كل حبة من بذور التمر الهندي K23 يتم اختيارها بعناية لإضفاء لمسة خاصة على أطباقك.
        //            \u00a0\u00a0\u00a0\u00a0 ميزة أخرى لحمض بذور K23 1 كجم من ماركة Cap Gunung تكمن في عبوته العملية والصحية. بفضل التصميم الذي يسهل فتحه وإغلاقه مرة أخرى، يحافظ هذا المنتج على نضارته وجودته حتى يتم استخدامه بالكامل. كما تحمي العبوة القوية الحبوب من التعرض للهواء والضوء، مما يحافظ على الرائحة والطعم الأصيل. باستخدام K23 Seed Asam من العلامة التجارية Cap Gunung، يمكنك إضفاء أفضل المذاق على كل طبق إندونيسي تقليدي، مما يجعله خيارًا لا يمكن استبداله في مطبخك.
        //          `,
        //         "kategori": "biji",
        //         "image": gambar14,
        //         "ukuran": 1000,
        //         "brand": "gunung",
        //         "jenis": "يكتب",
        //         "asam": "حمض البذور",
        //         "kemasan": "التعبئة والتغليف",
        //         "tradisional": "تقليدي",
        //         "harga": "سعر",
        //         "total": "AED 80.57/هكذا",
        //         "isi": "يملأ",
        //         "total_isi": "20 عبوة",
        //     },
        //     zh: {
        //         "nama": "K23 种子酸 1 公斤 Cap Gunung",
        //         "deskripsi": `
        //            \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 Asam Biji K23 1 Kg 是正宗印尼美食爱好者的完美选择。 该产品由值得信赖的品牌精心生产，提供最佳品质，确保您的菜肴美味。 K23种子酸采用1公斤包装，为日常使用提供便利和实用。 它的真实性和独特的酸味使其成为从仁当到咖喱的各种传统菜肴中不可忽视的香料。 Cap Gunung 作为香料行业的领先制造商，保证每一粒 K23 罗望子种子都经过精心挑选，为您的菜肴带来特殊的风味。
        //            \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 K23 种子酸 1 公斤的另一个优势在于其实用且卫生的包装。 该产品采用易于再次打开和关闭的设计，可在完全使用之前保持其新鲜度和品质。 坚固的包装还可以保护咖啡豆免于暴露在空气和光线下，保留正宗的香气和味道。 通过使用 Cap Gunung 品牌的 K23 亚参籽，您可以为每道传统印尼菜肴带来最好的味道，使其成为您厨房中不可替代的选择。
        //          `,
        //         "kategori": "biji",
        //         "image": gambar14,
        //         "ukuran": 1000,
        //         "brand": "gunung",
        //         "jenis": "类型",
        //         "asam": "种子酸",
        //         "kemasan": "包装",
        //         "tradisional": "传统的",
        //         "harga": "价格",
        //         "total": "CNY 156.34/杜斯",
        //         "isi": "内容",
        //         "total_isi": "20 包",
        //     },
        // },
        {
            id: {
                "nama": "Asam Biji 800 Kg Cap Bunga Merah",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa Biji 800 Kg Cap Bunga Merah kini tersedia untuk memenuhi kebutuhan pasar akan produk rempah berkualitas. Asam jawa ini terkenal dengan rasa asam yang kuat dan aroma khas, sangat cocok digunakan dalam berbagai olahan masakan tradisional maupun industri makanan. Kemasannya yang rapi dan bersih menjamin mutu dan kesegaran produk tetap terjaga hingga ke tangan konsumen. Dengan berat total 800 kg, produk ini sangat ideal untuk usaha kuliner skala besar, restoran, maupun distributor bahan pangan.
                \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah telah dikenal sebagai merek terpercaya yang selalu menawarkan produk berkualitas tinggi. Asam jawa biji ini tidak hanya digunakan sebagai bumbu, tetapi juga memiliki berbagai manfaat kesehatan, seperti membantu pencernaan dan meningkatkan imunitas. Selain itu, produk ini diproses secara higienis untuk menjaga kebersihan dan cita rasanya. Dengan ketersediaan dalam jumlah besar, pelanggan kini bisa lebih mudah mendapatkan pasokan yang stabil dan berkualitas sepanjang tahun.
                `,
                "kategori": "biji",
                "image": gambar36,
                "ukuran": 800,
                "jenis": "Jenis",
                "asam": "Asam Biji",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 12.000/dus",
                "isi": "Isi",
                "total_isi": "20 Pack",
            },
            en: {
                "nama": "Tamarind Seeds 800 Kg Red Flower Stamp",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind Seeds 800 Kg Cap Bunga Merah is now available to meet market needs for quality spice products. Tamarind is famous for its strong sour taste and distinctive aroma, very suitable for use in various traditional dishes and the food industry. Neat and clean packaging ensures that the quality and freshness of the product is maintained in the hands of consumers. With a total weight of 800 kg, this product is ideal for large-scale culinary businesses, restaurants and food distributors.
                \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah has been known as a trusted brand that always offers high quality products. Tamarind seeds are not only used as a spice, but also have various health benefits, such as helping digestion and increasing immunity. Apart from that, this product is processed hygienically to maintain cleanliness and taste. With availability in large quantities, customers can now more easily obtain stable and quality supplies throughout the year.
                `,
                "kategori": "biji",
                "image": gambar36,
                "ukuran": 800,
                "jenis": "Type",
                "asam": "Seed Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 0.77/dus",
                "isi": "Fill",
                "total_isi": "20 Pack",
            },
            ar: {
                "nama": "بذور عصام 800 كغ زهرة حمراء ختم",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 بذور التمر الهندي 800 كجم Cap Bunga Merah متاحة الآن لتلبية احتياجات السوق من منتجات التوابل عالية الجودة. يشتهر التمر الهندي بطعمه الحامض القوي ورائحته المميزة، وهو مناسب جداً للاستخدام في مختلف الأطباق التقليدية وصناعة المواد الغذائية. يضمن التغليف الأنيق والنظيف الحفاظ على جودة المنتج ونضارته في أيدي المستهلكين. بوزن إجمالي يبلغ 800 كجم، يعد هذا المنتج مثاليًا لشركات الطهي والمطاعم وموزعي الأطعمة على نطاق واسع.
                \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah بأنها علامة تجارية موثوقة تقدم دائمًا منتجات عالية الجودة. لا تستخدم بذور التمر الهندي كتوابل فحسب، بل لها فوائد صحية مختلفة أيضًا، مثل المساعدة على الهضم وزيادة المناعة. وبصرف النظر عن ذلك، يتم معالجة هذا المنتج صحيًا للحفاظ على نظافته وطعمه. ومع توفر كميات كبيرة، يمكن للعملاء الآن الحصول بسهولة أكبر على إمدادات مستقرة وعالية الجودة على مدار العام.
                `,
                "kategori": "biji",
                "image": gambar36,
                "ukuran": 800,
                "jenis": "يكتب",
                "asam": "حمض البذور",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 0.000236/هكذا",
                "isi": "يملأ",
                "total_isi": "20 عبوة",
            },
            zh: {
                "nama": "阿三籽800公斤红花邮票",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 罗望子种子 800 公斤 Cap Bunga Merah 现已上市，可满足市场对优质香料产品的需求。罗望子以其浓郁的酸味和独特的香气而闻名，非常适合用于各种传统菜肴和食品工业。整洁干净的包装确保产品的质量和新鲜度保持在消费者手中。该产品总重量为 800 公斤，是大型烹饪企业、餐馆和食品经销商的理想选择。
                \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah 被誉为始终提供高品质产品的值得信赖的品牌。罗望子种子不仅用作香料，还具有多种健康益处，例如帮助消化和增强免疫力。除此之外，该产品经过卫生加工，以保持清洁度和味道。由于供货量大，客户现在可以更轻松地全年获得稳定、优质的供应。
                `,
                "kategori": "biji",
                "image": gambar36,
                "ukuran": 800,
                "jenis": "类型",
                "asam": "种子酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 0.00045/杜斯",
                "isi": "内容",
                "total_isi": "20 包",
            },
        },
        {
            id: {
                "nama": "Asam Biji 1 Kg Cap Bunga Merah",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Biji 1 Kg dari Brand Cap Bunga Merah menghadirkan keunikan rasa yang luar biasa dalam setiap sajian kuliner. Brand Cap Bunga Merah dengan teliti memilih biji asam berkualitas tinggi untuk menciptakan produk yang memenuhi standar kualitas tertinggi. Dalam kemasan seberat 1 kilogram, Asam Biji ini bukan hanya memberikan kemudahan dalam penggunaan sehari-hari, tetapi juga memberikan inspirasi kreatifitas untuk menciptakan hidangan istimewa dengan nuansa asam yang tak terlupakan.
                \u00a0\u00a0\u00a0\u00a0 Brand Cap Bunga Merah menampilkan komitmen tinggi terhadap cita rasa istimewa dan pengalaman kuliner yang tak tergantikan. Keasaman yang terjaga dengan baik dan karakter rasa yang khas membuat Asam Biji 1 Kg ini menjadi bahan andalan yang cocok untuk berbagai macam resep masakan. Dengan Brand Cap Bunga Merah, nikmati kelezatan sejati dari Asam Biji 1 Kg dan tambahkan sentuhan spesial pada setiap hidangan Anda, menciptakan pengalaman kuliner yang memuaskan dan menggugah selera.
                `,
                "kategori": "biji",
                "image": gambar15,
                "ukuran": 1000,
                "jenis": "Jenis",
                "asam": "Asam Biji",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 338.000/dus",
                "isi": "Isi",
                "total_isi": "20 Pack",
            },
            en: {
                "nama": "Tamarind Seeds 1 Kg Red Flower Cap",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind Seeds 1 Kg from the Cap Bunga Merah Brand brings a unique, extraordinary taste to every culinary dish. The Cap Bunga Merah brand carefully selects high-quality tamarind seeds to create products that meet the highest quality standards. In a 1 kilogram package, Asam Biji not only provides convenience for daily use, but also provides creative inspiration to create special dishes with unforgettable sour nuances.
                \u00a0\u00a0\u00a0\u00a0 The Cap Bunga Merah brand displays a high commitment to exceptional taste and irreplaceable culinary experiences. The well-maintained acidity and distinctive taste character make this 1 Kg Seed Tamarind a reliable ingredient that is suitable for various kinds of recipes. With the Red Flower Cap Brand, enjoy the true deliciousness of Asam Biji 1 Kg and add a special touch to each of your dishes, creating a satisfying and appetizing culinary experience.
                `,
                "kategori": "biji",
                "image": gambar15,
                "ukuran": 1000,
                "jenis": "Type",
                "asam": "Seed Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 21.94/dus",
                "isi": "Fill",
                "total_isi": "20 Pack",
            },
            ar: {
                "nama": "بذور التمر الهندي 1 كجم غطاء زهرة حمراء",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 بذور التمر الهندي 1 كجم من العلامة التجارية Cap Bunga Merah تجلب مذاقًا فريدًا وغير عادي لكل طبق طهي. تختار العلامة التجارية Cap Bunga Merah بعناية بذور التمر الهندي عالية الجودة لإنشاء منتجات تلبي أعلى معايير الجودة. في عبوة يبلغ وزنها 1 كيلوجرام، لا يوفر Asam Biji الراحة للاستخدام اليومي فحسب، بل يوفر أيضًا الإلهام الإبداعي لإنشاء أطباق خاصة مع الفروق الدقيقة الحامضة التي لا تُنسى.
                \u00a0\u00a0\u00a0\u00a0 تُظهر العلامة التجارية Cap Bunga Merah التزامًا كبيرًا بالذوق الاستثنائي وتجارب الطهي التي لا يمكن استبدالها. الحموضة التي يتم الحفاظ عليها جيدًا وطابع الطعم المميز تجعل من 1 كجم من بذور التمر الهندي مكونًا موثوقًا ومناسبًا لأنواع مختلفة من الوصفات. مع العلامة التجارية Red Flower Cap، استمتع باللذة الحقيقية لـ Asam Biji 1 Kg وأضف لمسة خاصة إلى كل طبق من أطباقك، مما يخلق تجربة طهي مرضية وشهية.
                `,
                "kategori": "biji",
                "image": gambar15,
                "ukuran": 1000,
                "jenis": "يكتب",
                "asam": "حمض البذور",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 80.57/هكذا",
                "isi": "يملأ",
                "total_isi": "20 عبوة",
            },
            zh: {
                "nama": "罗望子种子 1 公斤 红花帽",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah 品牌的 1 公斤阿萨姆籽为每道菜肴带来独特、非凡的味道。 Cap Bunga Merah 品牌精心挑选优质罗望子种子，打造出符合最高质量标准的产品。 1公斤包装的阿三碧鸡不仅为日常使用提供了便利，还为创造具有令人难忘的酸味的特色菜肴提供了创作灵感。
                \u00a0\u00a0\u00a0\u00a0 Cap Bunga Merah 品牌展现了对卓越品味和不可替代的烹饪体验的高度承诺。 保持良好的酸度和独特的口味特征使这款 1 公斤种子罗望子成为适合各种食谱的可靠成分。 有了红花帽品牌，您就能享受到1公斤阿三酸果的真正美味，为您的每道菜肴增添独特的风味，创造出令人满意、开胃的美食体验。
                `,
                "kategori": "biji",
                "image": gambar15,
                "ukuran": 1000,
                "jenis": "类型",
                "asam": "种子酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 156.34/杜斯",
                "isi": "内容",
                "total_isi": "20 包",
            },
        },
        // {
        //     id: {
        //         "nama": "Asam Biji 10 Kg Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 10 Kg dari brand Cap Gunung merupakan produk unggulan yang memberikan kelezatan dan kualitas terbaik untuk pengalaman memasak yang tak tertandingi. Diproduksi oleh brand yang telah dikenal luas akan dedikasinya terhadap bahan-bahan berkualitas tinggi, asam biji ini menjadi pilihan ideal untuk memperkaya rasa masakan Anda. Dengan kemasan 10 Kg, produk ini memberikan ketersediaan yang cukup untuk kebutuhan dapur sehari-hari, memberikan nilai tambah yang luar biasa bagi para pecinta masakan yang menghargai keaslian dan cita rasa yang otentik.
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung telah membangun reputasi sebagai produsen yang mengedepankan standar kualitas tinggi, dan Asam Biji 10 Kg tak terkecuali. Dikemas dengan cermat untuk menjaga keaslian dan kesegaran, asam biji ini menjalani proses produksi yang ketat untuk memastikan bahwa setiap kemasan memberikan konsistensi dan kepuasan yang khas dari brand Cap Gunung. Dengan mengandalkan produk ini, Anda tidak hanya mendapatkan rasa yang istimewa, tetapi juga menikmati kepercayaan bahwa setiap hidangan yang Anda sajikan diperkaya oleh bahan-bahan terbaik yang dapat diandalkan.
        //         `,
        //         "kategori": "biji",
        //         "brand": "gunung",
        //         "image": gambar24,
        //         "ukuran": 1000
        //     },
        //     en: {
        //         "nama": "Tamarind Seeds 10 Kg Brand Mountain",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 10 Kg from the Cap Gunung brand is a superior product that provides deliciousness and the best quality for an unmatched cooking experience. Produced by a brand that is widely known for its dedication to high-quality ingredients, this tamarind is an ideal choice to enrich the taste of your cooking. With a 10 Kg packaging, this product provides sufficient availability for daily kitchen needs, providing extraordinary added value for food lovers who value authenticity and authentic taste.
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung has built a reputation as a manufacturer that prioritizes high quality standards, and Asam Biji 10 Kg is no exception. Carefully packaged to maintain authenticity and freshness, this seed acid undergoes a strict production process to ensure that each package provides the consistency and satisfaction that is typical of the Cap Gunung brand. By relying on this product, you not only get a special taste, but also enjoy the confidence that every dish you serve is enriched by the best, reliable ingredients.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar24,
        //         "ukuran": 1000
        //     },
        //     ar: {
        //         "nama": "بذور عصام 10 كيلو كاب جونونج",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Asam Biji 10 Kg من ماركة Cap Gunung هو منتج متميز يوفر اللذة وأفضل جودة لتجربة طهي لا مثيل لها. تم إنتاج هذا التمر الهندي من قبل علامة تجارية معروفة على نطاق واسع بتفانيها في تقديم مكونات عالية الجودة، وهو خيار مثالي لإثراء مذاق طبخك. مع عبوة 10 كجم، يوفر هذا المنتج توافرًا كافيًا لاحتياجات المطبخ اليومية، مما يوفر قيمة مضافة غير عادية لمحبي الطعام الذين يقدرون الأصالة والذوق الأصيل.
        //         \u00a0\u00a0\u00a0\u00a0 قامت Cap Gunung ببناء سمعة طيبة كشركة مصنعة تعطي الأولوية لمعايير الجودة العالية، وAsam Biji 10 Kg ليس استثناءً. تم تعبئته بعناية للحفاظ على الأصالة والنضارة، ويخضع حمض البذور هذا لعملية إنتاج صارمة للتأكد من أن كل عبوة توفر الاتساق والرضا الذي تتميز به علامة Cap Gunung التجارية. من خلال الاعتماد على هذا المنتج، فإنك لا تحصل على مذاق خاص فحسب، بل ستستمتع أيضًا بالثقة في أن كل طبق تقدمه غني بأفضل المكونات الموثوقة.
        //         `,
        //         "kategori": "biji",
        //         "image": gambar24,
        //         "ukuran": 1000
        //     },
        //     zh: {
        //         "nama": "阿三籽 10 公斤 Cap Gunung",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的 Asam Biji 10 Kg 是一款优质产品，提供美味和最佳品质，带来无与伦比的烹饪体验。 这种罗望子由一个以致力于高品质原料而闻名的品牌生产，是丰富烹饪味道的理想选择。 该产品采用 10 公斤包装，可满足日常厨房需求，为重视真实性和正宗口味的美食爱好者提供非凡的附加值。
        //         \u00a0\u00a0\u00a0\u00a0 Cap Gunung 作为优先考虑高质量标准的制造商而享有盛誉，Asam Biji 10 Kg 也不例外。 这种种子酸经过严格的生产过程，经过精心包装，以保持真实性和新鲜度，以确保每个包装都能提供 Cap Gunung 品牌特有的一致性和满意度。 通过依赖该产品，您不仅可以获得特殊的口味，而且可以放心，您提供的每道菜都是由最好、可靠的原料制成的。
        //         `,
        //         "kategori": "biji",
        //         "image": gambar24,
        //         "ukuran": 1000
        //     },
        // },
        {
            id: {
                "nama": "Bahan Baku 1",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar16,
            },
            en: {
                "nama": "Bahan Baku 1",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar16,
            },
            ar: {
                "nama": "Bahan Baku 1",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar16,
            },
            zh: {
                "nama": "Bahan Baku 1",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar16,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 2",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar17,
            },
            en: {
                "nama": "Bahan Baku 2",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar17,
            },
            ar: {
                "nama": "Bahan Baku 2",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar17,
            },
            zh: {
                "nama": "Bahan Baku 2",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar17,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 3",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar18,
            },
            en: {
                "nama": "Bahan Baku 3",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar18,
            },
            ar: {
                "nama": "Bahan Baku 3",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar18,
            },
            zh: {
                "nama": "Bahan Baku 3",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar18,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 4",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar19,
            },
            en: {
                "nama": "Bahan Baku 4",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar19,
            },
            ar: {
                "nama": "Bahan Baku 4",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar19,
            },
            zh: {
                "nama": "Bahan Baku 4",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar19,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 5",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar25,
            },
            en: {
                "nama": "Bahan Baku 5",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar25,
            },
            ar: {
                "nama": "Bahan Baku 5",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar25,
            },
            zh: {
                "nama": "Bahan Baku 5",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar25,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar26,
            },
            en: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar26,
            },
            ar: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar26,
            },
            zh: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar26,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar27,
            },
            en: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar27,
            },
            ar: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar27,
            },
            zh: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar27,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar32,
            },
            en: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar32,
            },
            ar: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar32,
            },
            zh: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar32,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar33,
            },
            en: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar33,
            },
            ar: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar33,
            },
            zh: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar33,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar34,
            },
            en: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar34,
            },
            ar: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar34,
            },
            zh: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar34,
            },
        },
        {
            id: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Jawa dari brand kami menghadirkan bahan baku berkualitas tinggi yang mencakup daging dan biji, memenuhi kebutuhan kuliner Anda dengan kelezatan yang luar biasa. Bahan baku ini tidak hanya memberikan rasa asam yang khas, tetapi juga mempertahankan kandungan biji sebesar 3 sampai 5%, menambahkan dimensi tekstur yang unik pada setiap hidangan. Keunikan produk ini tidak hanya terletak pada kualitasnya, tetapi juga pada ketersediaannya dalam kemasan karungan, memberikan kemudahan dalam penggunaan, penyimpanan, dan tersedia untuk penjualan karungan.
                \u00a0\u00a0\u00a0\u00a0 Dengan Asam Jawa yang menggabungkan daging dan biji, Anda dapat dengan mudah menciptakan masakan dengan rasa autentik dan karakteristik yang istimewa. Kemasan karungan juga menambahkan tingkat kenyamanan ekstra, memungkinkan Anda untuk dengan praktis mengakses bahan baku ini saat berkreasi di dapur atau memenuhi kebutuhan penjualan karungan dalam skala yang lebih besar. Segera nikmati pengalaman kuliner yang lebih kaya dan beragam dengan Asam Jawa dari brand kami, yang tidak hanya memenuhi kebutuhan masakan Anda tetapi juga menawarkan kemudahan dalam setiap langkahnya.
                `,
                "kategori": "bahan-baku",
                "image": gambar35,
            },
            en: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Tamarind from our brand features high-quality raw materials that include flesh and seeds, meeting your culinary needs with extraordinary deliciousness. This raw material not only provides a distinctive sour taste, but also maintains a seed content of 3 to 5%, adding a unique textural dimension to every dish. The uniqueness of this product lies not only in its quality, but also in its availability in sack packaging, providing ease of use, storage, and is available for sale in sacks.
                \u00a0\u00a0\u00a0\u00a0 With Tamarind that combines flesh and seeds, you can easily create dishes with authentic taste and special characteristics. Sack packaging also adds an extra level of convenience, allowing you to conveniently access these raw materials when getting creative in the kitchen or meeting your bag sales needs on a larger scale. Immediately enjoy a richer and more varied culinary experience with Tamarind from our brand, which not only meets your cooking needs but also offers convenience every step of the way.
                `,
                "kategori": "bahan-baku",
                "image": gambar35,
            },
            ar: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 التمر الهندي من علامتنا التجارية يتميز بمواد خام عالية الجودة تشمل اللحم والبذور، مما يلبي احتياجاتك في الطهي بمذاق استثنائي. لا توفر هذه المادة الخام طعمًا حامضًا مميزًا فحسب، بل تحافظ أيضًا على محتوى البذور بنسبة 3 إلى 5%، مما يضيف بُعدًا تركيبيًا فريدًا لكل طبق. لا يكمن تفرد هذا المنتج في جودته فحسب، بل أيضًا في توفره في أكياس التعبئة والتغليف، مما يوفر سهولة الاستخدام والتخزين، وهو متاح للبيع في أكياس.
                \u00a0\u00a0\u00a0\u00a0 مع التمر الهندي الذي يجمع بين اللحم والبذور، يمكنك بسهولة إعداد أطباق ذات طعم أصيل وخصائص خاصة. يضيف تغليف الأكياس أيضًا مستوى إضافيًا من الراحة، مما يسمح لك بالوصول بسهولة إلى هذه المواد الخام عند الإبداع في المطبخ أو تلبية احتياجات مبيعات الحقائب الخاصة بك على نطاق أوسع. استمتع على الفور بتجربة طهي أكثر ثراءً وتنوعًا مع التمر الهندي من علامتنا التجارية، والتي لا تلبي احتياجات الطبخ الخاصة بك فحسب، بل توفر أيضًا الراحة في كل خطوة على الطريق.
                `,
                "kategori": "bahan-baku",
                "image": gambar35,
            },
            zh: {
                "nama": "Bahan Baku 6",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 我们品牌的罗望子采用优质原料，包括果肉和种子，以非凡的美味满足您的烹饪需求。 这种原料不仅具有独特的酸味，而且种子含量保持在 3% 至 5%，为每道菜增添了独特的质感。 该产品的独特之处不仅在于其质量，还在于其采用袋装包装，易于使用、储存，并且可以袋装销售。
                \u00a0\u00a0\u00a0\u00a0 使用肉与籽结合的罗望子，您可以轻松制作出味道正宗、特色鲜明的菜肴。 麻袋包装还增加了额外的便利性，让您在厨房发挥创意或满足更大规模的袋子销售需求时可以方便地获取这些原材料。 使用我们品牌的 Tamarind 立即享受更丰富、更多样化的烹饪体验，这不仅满足您的烹饪需求，而且为每一步提供便利。
                `,
                "kategori": "bahan-baku",
                "image": gambar35,
            },
        },
        // {
        //     id: {
        //         "nama": "Asam Daging 40gr (Kemasan 500gr isi 12)",
        //         "deskripsi": "Dengan berat 40 gram, produk ini dapat digunakan dalam beberapa hidangan atau variasi masakan sesuai kebutuhan. Kemasan yang lebih besar, yaitu 500 gram, mungkin cocok untuk penggunaan yang lebih sering atau untuk kebutuhan dalam jumlah besar, seperti di restoran atau dapur komersial.",
        //         "kategori": "daging",
        //         "image": gambar20,
        //         "ukuran": 40
        //     },
        //     en: {
        //         "nama": "Meat Tamarind 40g (500g pack contains 12)",
        //         "deskripsi": "Weighing 40 grams, this product can be used in several dishes or a variety of dishes as needed. Larger packages, namely 500 grams, may be suitable for more frequent use or for large quantities, such as in restaurants or commercial kitchens.",
        //         "kategori": "daging",
        //         "image": gambar20,
        //         "ukuran": 40
        //     },
        //     ar: {
        //         "nama": "لحم بالتمر الهندي 40 جرام (عبوة 500 جرام تحتوي على 12 قطعة)",
        //         "deskripsi": "يزن هذا المنتج 40 جرامًا، ويمكن استخدامه في العديد من الأطباق أو أشكال الطهي حسب الحاجة. قد تكون العبوات الأكبر حجمًا، أي 500 جرام، مناسبة للاستخدام المتكرر أو للكميات الكبيرة، كما هو الحال في المطاعم أو المطابخ التجارية.",
        //         "kategori": "daging",
        //         "image": gambar20,
        //         "ukuran": 40
        //     },
        //     zh: {
        //         "nama": "肉罗望子 40 克（500 克包装包含 12 个）",
        //         "deskripsi": "该产品重 40 克，可根据需要用于多种菜肴或烹饪变化。 较大的包装（即 500 克）可能适合更频繁的使用或大量使用，例如在餐馆或商业厨房中。",
        //         "kategori": "daging",
        //         "image": gambar20,
        //         "ukuran": 40
        //     },
        // },
        {
            id: {
                "nama": "Asam Daging 500 Gram Baru Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Asam Daging 500 Gram Baru dari brand Cap Gunung adalah pilihan terdepan untuk menambahkan kelezatan pada setiap hidangan Anda. Dikemas dalam kemasan praktis berukuran 500 gram, produk ini memancarkan kualitas terbaik yang dihadirkan oleh brand Cap Gunung. Keunikan dari Asam Daging ini terletak pada keberadaan kandungan biji yang masih dipertahankan sebesar 3 sampai 5%, menambahkan elemen autentik pada setiap kreasi kuliner Anda.
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung memberikan sentuhan inovatif pada Asam Daging 500 Gram Baru dengan mempertahankan kandungan biji, menciptakan pengalaman kuliner yang lebih beragam. Rasa asam yang khas dan tekstur yang unik dari biji memberikan dimensi baru pada masakan Anda. Dengan kemasan yang kompak, produk ini tidak hanya praktis tetapi juga memberikan kebebasan kepada Anda untuk eksplorasi kuliner yang kreatif dan memuaskan selera dengan setiap sajian.
                `,
                "kategori": "daging",
                "image": gambar21,
                "ukuran": 500,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "Jenis",
                "asam": "Asam Daging",
                "kemasan": "Kemasan",
                "tradisional": "Tradisional",
                "harga": "Harga",
                "total": "IDR 476.500/dus",
                "isi": "Isi",
                "total_isi": "400 Pack",
            },
            en: {
                "nama": "Meat Tamarind 500 Grams New Brand Mountain",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 The New 500 Gram Meat Tamarind from the Cap Gunung brand is the leading choice for adding deliciousness to each of your dishes. Packaged in practical packaging measuring 500 grams, this product exudes the best quality presented by the Cap Gunung brand. The uniqueness of this Meat Tamarind lies in the presence of seed content which is still maintained at 3 to 5%, adding an authentic element to each of your culinary creations.
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung provides an innovative touch to the New 500 Gram Meat Tamarind by retaining the seed content, creating a more diverse culinary experience. The distinctive sour taste and unique texture of the seeds add a new dimension to your cooking. With compact packaging, this product is not only practical but also gives you the freedom to explore creative culinary delights and satisfy your taste buds with every dish.
                `,
                "kategori": "daging",
                "image": gambar21,
                "ukuran": 500,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "Type",
                "asam": "Meat Acid",
                "kemasan": "Packaging",
                "tradisional": "Traditional",
                "harga": "Price",
                "total": "USD 30.93/dus",
                "isi": "Contents",
                "total_isi": "40 Packs",
            },
            ar: {
                "nama": "لحم تمر هندي 500 جرام نيو كاب جونونج",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 يعتبر لحم التمر الهندي الجديد 500 جرام من العلامة التجارية Cap Gunung هو الخيار الرائد لإضافة المذاق اللذيذ إلى كل طبق من أطباقك. معبأ في عبوة عملية بوزن 500 جرام، يتميز هذا المنتج بأفضل جودة تقدمها العلامة التجارية Cap Gunung. يكمن تفرد لحم التمر الهندي هذا في وجود محتوى البذور الذي لا يزال محتفظًا به بنسبة 3 إلى 5%، مما يضيف عنصرًا أصيلًا إلى كل إبداعات الطهي الخاصة بك.
                \u00a0\u00a0\u00a0\u00a0 يوفر Cap Gunung لمسة مبتكرة لـ 500 جرام لحم التمر الهندي الجديد من خلال الاحتفاظ بمحتوى البذور، مما يخلق تجربة طهي أكثر تنوعًا. يضيف الطعم الحامض المميز والملمس الفريد للبذور بعدًا جديدًا إلى طبخك. بفضل العبوة المدمجة، فإن هذا المنتج ليس عمليًا فحسب، بل يمنحك أيضًا الحرية في استكشاف المأكولات اللذيذة وإرضاء ذوقك بكل طبق.
                `,
                "kategori": "daging",
                "image": gambar21,
                "ukuran": 500,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "يكتب",
                "asam": "حمض اللحوم",
                "kemasan": "التعبئة والتغليف",
                "tradisional": "تقليدي",
                "harga": "سعر",
                "total": "AED 113.59/هكذا",
                "isi": "محتويات",
                "total_isi": "40 عبوة",
            },
            zh: {
                "nama": "肉罗望子 500 克 New Cap Gunung",
                "deskripsi": `
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 品牌的新款 500 克肉罗望子是为您的每道菜增添美味的首选。 该产品采用重 500 克的实用包装，散发出 Cap Gunung 品牌的最佳品质。 这种肉罗望子的独特之处在于其种子含量仍保持在 3% 至 5%，为您的每件烹饪作品增添了真实的元素。
                \u00a0\u00a0\u00a0\u00a0 Cap Gunung 通过保留种子成分，为新型 500 克肉罗望子提供了创新的触感，创造了更加多样化的烹饪体验。 种子独特的酸味和独特的质地为您的烹饪增添了新的维度。 包装紧凑，不仅实用，还能让您自由探索创意美食，用每一道菜满足您的味蕾。
                `,
                "kategori": "daging",
                "image": gambar21,
                "ukuran": 500,
                "brand": "gunung",
                "versi": "baru",
                "jenis": "类型",
                "asam": "肉酸",
                "kemasan": "包装",
                "tradisional": "传统的",
                "harga": "价格",
                "total": "CNY 220.40/杜斯",
                "isi": "内容",
                "total_isi": "40 包",
            },
        },

        // {
        //     id: {
        //         "nama": "Kemiri Bulat",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Nikmati keajaiban kemiri bulat dalam setiap hidangan Anda! Kemiri bulat premium kami dipilih dengan cermat dari sumber terbaik untuk memastikan kualitas dan rasa yang luar biasa. Tidak hanya menambah cita rasa gurih pada masakan, kemiri bulat juga kaya akan nutrisi yang bermanfaat bagi kesehatan. Dengan tekstur yang halus dan aroma khas yang menggoda, kemiri bulat ini sangat ideal untuk bumbu dapur, membuat saus, hingga menjadi pelengkap sempurna dalam berbagai kuliner tradisional dan modern.
        //         \u00a0\u00a0\u00a0\u00a0 Selain manfaatnya dalam memasak, kemiri bulat juga terkenal sebagai bahan alami perawatan rambut. Minyak kemiri yang diekstrak dari kemiri bulat telah lama digunakan untuk memperkuat rambut, mengurangi kerontokan, dan menambah kilau alami. Gunakan kemiri bulat premium kami untuk membuat minyak kemiri DIY di rumah dan rasakan perbedaannya! Dapatkan kemiri bulat premium sekarang dan alami manfaat ganda dalam masakan serta perawatan kecantikan Anda.
        //         `,
        //         "kategori": "kemiri",
        //         "image": gambar29,
        //         "ukuran": 500,
        //     },
        //     en: {
        //         "nama": "Round Pecan",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Enjoy the magic of round pecans in every meal! Our premium round pecans are carefully selected from the finest sources to ensure exceptional quality and taste. Not only do they add a savory taste to dishes, round candlenuts are also rich in nutrients that are beneficial for health. With its smooth texture and distinctive, tempting aroma, this round candlenut is ideal for seasoning, making sauces, and is a perfect addition to various traditional and modern culinary delights.
        //         \u00a0\u00a0\u00a0\u00a0 Apart from its benefits in cooking, round candlenuts are also famous as a natural hair care ingredient. Candlenut oil extracted from round candlenuts has long been used to strengthen hair, reduce hair loss, and add natural shine. Use our premium round hazelnuts to make DIY hazelnut oil at home and feel the difference! Get premium round candlenuts now and experience double benefits in your cooking and beauty care.
        //         `,
        //         "kategori": "kemiri",
        //         "image": gambar29,
        //         "ukuran": 500,
        //     },
        //     ar: {
        //         "nama": "البقان المستدير",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 استمتع بسحر جوز البقان المستدير في كل وجبة! يتم اختيار جوز البقان المستدير الفاخر لدينا بعناية من أجود المصادر لضمان الجودة والمذاق الاستثنائي. فهي لا تضيف طعمًا لذيذًا إلى الأطباق فحسب، بل إن الشموع المستديرة غنية أيضًا بالمواد المغذية المفيدة للصحة. بفضل ملمسه الناعم ورائحته المميزة والمغرية، يعتبر هذا الجوز المستدير مثاليًا للتتبيل وصنع الصلصات، وهو إضافة مثالية لمختلف المأكولات التقليدية والحديثة.
        //         \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن فوائده في الطبخ، فإن الشموع المستديرة مشهورة أيضًا كعنصر طبيعي للعناية بالشعر. يستخدم زيت الشمع المستخرج من حبات الشمع المستديرة منذ فترة طويلة لتقوية الشعر وتقليل تساقط الشعر وإضافة لمعان طبيعي. استخدمي البندق المستدير الفاخر الخاص بنا لصنع زيت البندق في المنزل واشعري بالفرق! احصل الآن على شموع مستديرة ممتازة واستمتع بفوائد مضاعفة في الطبخ والعناية بالجمال.
        //         `,
        //         "kategori": "kemiri",
        //         "image": gambar29,
        //         "ukuran": 500,
        //     },
        //     zh: {
        //         "nama": "圆山核桃",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 每顿饭都享受圆形山核桃的魔力！我们的优质圆形山核桃是从最好的来源中精心挑选的，以确保卓越的品质和口味。圆石榴不仅能为菜肴增添美味，还富含有益健康的营养成分。这种圆形石栗果质地光滑，香气独特诱人，非常适合调味、制作酱汁，是各种传统和现代美食的完美补充。
        //         \u00a0\u00a0\u00a0\u00a0 除了在烹饪方面的好处外，圆烛果还作为天然护发成分而闻名。从圆形石蜡树中提取的石蜡油长期以来一直被用来增强头发、减少脱发并增加自然光泽。使用我们的优质圆形榛子在家自制榛子油，感受与众不同！立即购买优质圆烛坚果，体验烹饪和美容护理的双重好处。
        //         `,
        //         "kategori": "kemiri",
        //         "image": gambar29,
        //         "ukuran": 500,
        //     },
        // },

        // {
        //     id: {
        //         "nama": "Kayu Manis",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Kayu manis premium kami dipilih dengan teliti untuk memberikan aroma yang khas dan cita rasa yang kaya pada setiap hidangan Anda. Kayu manis ini sempurna untuk menambah kehangatan dan sentuhan manis pada berbagai resep, mulai dari kue, roti, hingga minuman seperti teh dan kopi. Kualitas terbaik kayu manis ini memastikan setiap batangnya memberikan rasa yang intens dan autentik, membuat masakan Anda lebih istimewa dan lezat.
        //         \u00a0\u00a0\u00a0\u00a0 Selain kegunaannya dalam memasak, kayu manis juga dikenal memiliki berbagai manfaat kesehatan. Kayu manis kaya akan antioksidan yang membantu melawan radikal bebas dalam tubuh, serta memiliki sifat anti-inflamasi yang dapat meningkatkan kesehatan secara keseluruhan. Menambahkan kayu manis ke dalam diet Anda dapat membantu mengontrol gula darah, meningkatkan kesehatan jantung, dan bahkan memperkuat sistem kekebalan tubuh. Dapatkan kayu manis premium kami sekarang dan nikmati manfaatnya untuk rasa dan kesehatan Anda!
        //         `,
        //         "kategori": "kayu manis",
        //         "image": gambar30,
        //         "ukuran": 500,
        //     },
        //     en: {
        //         "nama": "Cinnamon",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Our premium cinnamon is carefully selected to provide a distinctive aroma and rich taste to each of your dishes. This cinnamon is perfect for adding warmth and a touch of sweetness to a variety of recipes, from cakes and breads to drinks such as tea and coffee. The finest quality of this cinnamon ensures that each stick provides an intense and authentic taste, making your dishes more special and delicious.
        //         \u00a0\u00a0\u00a0\u00a0 Apart from its use in cooking, cinnamon is also known to have various health benefits. Cinnamon is rich in antioxidants that help fight free radicals in the body, and has anti-inflammatory properties that can improve overall health. Adding cinnamon to your diet can help control blood sugar, improve heart health, and even strengthen the immune system. Get our premium cinnamon now and enjoy its benefits for your taste and health!
        //         `,
        //         "kategori": "kayu manis",
        //         "image": gambar30,
        //         "ukuran": 500,
        //     },
        //     ar: {
        //         "nama": "قرفة",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 يتم اختيار القرفة الفاخرة لدينا بعناية لتوفير رائحة مميزة وطعم غني لكل طبق من أطباقك. تعتبر هذه القرفة مثالية لإضافة الدفء ولمسة من الحلاوة إلى مجموعة متنوعة من الوصفات، من الكعك والخبز إلى المشروبات مثل الشاي والقهوة. تضمن أجود أنواع القرفة أن كل عود يوفر مذاقًا قويًا وأصيلًا، مما يجعل أطباقك أكثر تميزًا ولذيذة.
        //         \u00a0\u00a0\u00a0\u00a0 وبصرف النظر عن استخدامها في الطبخ، فمن المعروف أيضا أن القرفة لها فوائد صحية مختلفة. القرفة غنية بمضادات الأكسدة التي تساعد على محاربة الجذور الحرة في الجسم، ولها خصائص مضادة للالتهابات يمكن أن تحسن الصحة العامة. إن إضافة القرفة إلى نظامك الغذائي يمكن أن يساعد في التحكم في نسبة السكر في الدم، وتحسين صحة القلب، وحتى تقوية جهاز المناعة. احصل على القرفة الفاخرة الآن واستمتع بفوائدها لذوقك وصحتك!
        //         `,
        //         "kategori": "kayu manis",
        //         "image": gambar30,
        //         "ukuran": 500,
        //     },
        //     zh: {
        //         "nama": "肉桂",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 我们的优质肉桂经过精心挑选，可为您的每道菜提供独特的香气和丰富的味道。这种肉桂非常适合为各种食谱添加温暖和甜味，从蛋糕和面包到茶和咖啡等饮料。这种肉桂的最佳品质确保每根肉桂棒都能提供浓郁而正宗的味道，使您的菜肴更加特别和美味。
        //         \u00a0\u00a0\u00a0\u00a0 除了用于烹饪之外，肉桂还具有多种健康益处。肉桂富含抗氧化剂，有助于对抗体内自由基，并具有抗炎特性，可以改善整体健康。在饮食中添加肉桂可以帮助控制血糖，改善心脏健康，甚至增强免疫系统。立即购买我们的优质肉桂，享受它对您的口味和健康的好处！
        //         `,
        //         "kategori": "kayu manis",
        //         "image": gambar30,
        //         "ukuran": 500,
        //     },
        // },

        // {
        //     id: {
        //         "nama": "Kerupuk",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Temukan kenikmatan tiada tara dengan aneka kerupuk premium kami! Dibuat dari bahan-bahan berkualitas tinggi dan diproses dengan teknik tradisional yang terjaga, setiap gigitan kerupuk ini menghadirkan rasa yang autentik dan kelezatan yang tak tertandingi. Mulai dari kerupuk udang yang gurih, kerupuk ikan yang kaya protein, hingga kerupuk bawang yang aromatik, koleksi aneka kerupuk kami siap memanjakan lidah Anda. Sangat cocok untuk teman makan, camilan sore, atau tambahan dalam hidangan favorit Anda.
        //         \u00a0\u00a0\u00a0\u00a0 Aneka kerupuk kami tidak hanya menawarkan rasa yang lezat, tetapi juga tekstur yang sempurna – renyah di luar dan lembut di dalam. Dibungkus dengan rapi untuk menjaga kesegarannya, Anda dapat menikmati kerupuk ini kapan saja dan di mana saja. Sajikan sebagai camilan di acara kumpul keluarga, sebagai teman nonton film, atau sebagai pelengkap makanan berat, aneka kerupuk kami selalu menjadi pilihan yang tepat. Dapatkan aneka kerupuk premium kami sekarang dan rasakan kenikmatan tradisional yang selalu menggugah selera!
        //         `,
        //         "kategori": "kerupuk",
        //         "image": gambar31,
        //         "ukuran": 500,
        //     },
        //     en: {
        //         "nama": "Cracker",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 Discover incomparable enjoyment with our variety of premium crackers! Made from high quality ingredients and processed using maintained traditional techniques, every bite of these crackers presents an authentic taste and unmatched deliciousness. Starting from savory shrimp crackers, protein-rich fish crackers, to aromatic onion crackers, our collection of various crackers is ready to pamper your taste buds. It is perfect as a meal companion, afternoon snack, or addition to your favorite dish.
        //         \u00a0\u00a0\u00a0\u00a0 Our range of crackers not only offers delicious taste, but also the perfect texture – crunchy on the outside and soft on the inside. Neatly wrapped to maintain freshness, you can enjoy these crackers anytime and anywhere. Serve as a snack at family gatherings, as a companion for watching a film, or as a complement to a heavy meal, our various crackers are always the right choice. Get a variety of our premium crackers now and experience traditional delights that are always appetizing!
        //         `,
        //         "kategori": "kerupuk",
        //         "image": gambar31,
        //         "ukuran": 500,
        //     },
        //     ar: {
        //         "nama": "الروبيان هش",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 اكتشف متعة لا تضاهى مع مجموعتنا المتنوعة من المفرقعات المتميزة! مصنوعة من مكونات عالية الجودة وتم معالجتها باستخدام تقنيات تقليدية، كل قضمة من هذه المقرمشات تقدم مذاقًا أصيلًا ولذيذًا لا مثيل له. بدءًا من مقرمشات الجمبري اللذيذة، ومقرمشات الأسماك الغنية بالبروتين، إلى مقرمشات البصل العطرية، فإن مجموعتنا من المقرمشات المتنوعة جاهزة لتدليل ذوقك. إنه مثالي كرفيق للوجبة، أو كوجبة خفيفة بعد الظهر، أو كإضافة إلى طبقك المفضل.
        //         \u00a0\u00a0\u00a0\u00a0 لا تقدم مجموعتنا من المقرمشات طعمًا لذيذًا فحسب، بل توفر أيضًا الملمس المثالي - مقرمشة من الخارج وناعمة من الداخل. مغلفة بعناية للحفاظ على نضارتها، يمكنك الاستمتاع بهذه المقرمشات في أي وقت وفي أي مكان. يمكن تقديمها كوجبة خفيفة في التجمعات العائلية، أو كمرافق لمشاهدة فيلم، أو كمكمل لوجبة ثقيلة، فالمقرمشات المتنوعة لدينا هي دائمًا الخيار الصحيح. احصل على مجموعة متنوعة من المقرمشات المميزة لدينا الآن واستمتع بتجربة المأكولات التقليدية الشهية دائمًا!
        //         `,
        //         "kategori": "kerupuk",
        //         "image": gambar31,
        //         "ukuran": 500,
        //     },
        //     zh: {
        //         "nama": "虾酥",
        //         "deskripsi": `
        //         \u00a0\u00a0\u00a0\u00a0 体验我们各种优质饼干带来的无与伦比的享受！这些饼干采用优质原料制成，并采用传统技术加工，每一口都呈现出正宗的味道和无与伦比的美味。从美味的虾片、富含蛋白质的鱼片到芳香的洋葱片，我们的各种饼干系列已准备好满足您的味蕾。它非常适合作为正餐伴侣、下午点心或添加到您最喜爱的菜肴中。
        //         \u00a0\u00a0\u00a0\u00a0 我们的饼干系列不仅味道鲜美，而且质地完美——外脆内软。包装整齐，保持新鲜，您可以随时随地享用这些饼干。无论是作为家庭聚会的零食，作为看电影的伴侣，还是作为大餐的补充，我们的各种饼干始终是正确的选择。立即购买我们的各种优质饼干，体验总是令人胃口大开的传统美食！
        //         `,
        //         "kategori": "kerupuk",
        //         "image": gambar31,
        //         "ukuran": 500,
        //     },
        // }
    ]
            

export default dataProduk;